/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";
import { Boolean, booleanFromJSON, booleanToJSON } from "../../../common/pb/common";

export const protobufPackage = "apollo";

export interface EnterpriseModel {
  euuid: string;
  name: string;
  owner: string;
  numSeats: number;
  numFilledSeats: number;
  createdAt: number;
  updatedAt: number;
  admins: string[];
  subscriptionStart: number;
  subscriptionEnd: number;
  salesName: string;
  salesNumber: string;
  salesEmail: string;
}

export interface EmptyResponse {
}

export interface CreateEnterpriseRequest {
  name: string;
  owner: string;
  numSeats: number;
  subscriptionStart: number;
  subscriptionEnd: number;
  salesName: string;
  salesNumber: string;
  salesEmail: string;
}

export interface EditEnterpriseRequest {
  euuid: string;
  name: string;
  owner: string;
  numSeats: number;
  numFilledSeats: number;
  subscriptionStart: number;
  subscriptionEnd: number;
  admins: string[];
}

/** For retrieving information about any organizations that the member is part of */
export interface GetEnterpriseFromUserIDRequest {
  uuid: string;
}

export interface GetEnterpriseRequest {
  euuid: string;
}

export interface UpdateEnterpriseMembershipRequest {
  euuid: string;
  /**
   * True indicates the user is being added to the enterprise
   * False indicates that the uesr is being removed from the enterprise
   */
  onboarding: Boolean;
}

function createBaseEnterpriseModel(): EnterpriseModel {
  return {
    euuid: "",
    name: "",
    owner: "",
    numSeats: 0,
    numFilledSeats: 0,
    createdAt: 0,
    updatedAt: 0,
    admins: [],
    subscriptionStart: 0,
    subscriptionEnd: 0,
    salesName: "",
    salesNumber: "",
    salesEmail: "",
  };
}

export const EnterpriseModel = {
  encode(message: EnterpriseModel, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.euuid !== "") {
      writer.uint32(10).string(message.euuid);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.owner !== "") {
      writer.uint32(26).string(message.owner);
    }
    if (message.numSeats !== 0) {
      writer.uint32(32).int64(message.numSeats);
    }
    if (message.numFilledSeats !== 0) {
      writer.uint32(40).int64(message.numFilledSeats);
    }
    if (message.createdAt !== 0) {
      writer.uint32(48).int64(message.createdAt);
    }
    if (message.updatedAt !== 0) {
      writer.uint32(56).int64(message.updatedAt);
    }
    for (const v of message.admins) {
      writer.uint32(66).string(v!);
    }
    if (message.subscriptionStart !== 0) {
      writer.uint32(72).int64(message.subscriptionStart);
    }
    if (message.subscriptionEnd !== 0) {
      writer.uint32(80).int64(message.subscriptionEnd);
    }
    if (message.salesName !== "") {
      writer.uint32(90).string(message.salesName);
    }
    if (message.salesNumber !== "") {
      writer.uint32(98).string(message.salesNumber);
    }
    if (message.salesEmail !== "") {
      writer.uint32(106).string(message.salesEmail);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EnterpriseModel {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEnterpriseModel();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.euuid = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.owner = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.numSeats = longToNumber(reader.int64() as Long);
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.numFilledSeats = longToNumber(reader.int64() as Long);
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.createdAt = longToNumber(reader.int64() as Long);
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.updatedAt = longToNumber(reader.int64() as Long);
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.admins.push(reader.string());
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.subscriptionStart = longToNumber(reader.int64() as Long);
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.subscriptionEnd = longToNumber(reader.int64() as Long);
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.salesName = reader.string();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.salesNumber = reader.string();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.salesEmail = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EnterpriseModel {
    return {
      euuid: isSet(object.euuid) ? String(object.euuid) : "",
      name: isSet(object.name) ? String(object.name) : "",
      owner: isSet(object.owner) ? String(object.owner) : "",
      numSeats: isSet(object.num_seats) ? Number(object.num_seats) : 0,
      numFilledSeats: isSet(object.num_filled_seats) ? Number(object.num_filled_seats) : 0,
      createdAt: isSet(object.created_at) ? Number(object.created_at) : 0,
      updatedAt: isSet(object.updated_at) ? Number(object.updated_at) : 0,
      admins: Array.isArray(object?.admins) ? object.admins.map((e: any) => String(e)) : [],
      subscriptionStart: isSet(object.subscription_start) ? Number(object.subscription_start) : 0,
      subscriptionEnd: isSet(object.subscription_end) ? Number(object.subscription_end) : 0,
      salesName: isSet(object.sales_name) ? String(object.sales_name) : "",
      salesNumber: isSet(object.sales_number) ? String(object.sales_number) : "",
      salesEmail: isSet(object.sales_email) ? String(object.sales_email) : "",
    };
  },

  toJSON(message: EnterpriseModel): unknown {
    const obj: any = {};
    message.euuid !== undefined && (obj.euuid = message.euuid);
    message.name !== undefined && (obj.name = message.name);
    message.owner !== undefined && (obj.owner = message.owner);
    message.numSeats !== undefined && (obj.num_seats = Math.round(message.numSeats));
    message.numFilledSeats !== undefined && (obj.num_filled_seats = Math.round(message.numFilledSeats));
    message.createdAt !== undefined && (obj.created_at = Math.round(message.createdAt));
    message.updatedAt !== undefined && (obj.updated_at = Math.round(message.updatedAt));
    if (message.admins) {
      obj.admins = message.admins.map((e) => e);
    } else {
      obj.admins = [];
    }
    message.subscriptionStart !== undefined && (obj.subscription_start = Math.round(message.subscriptionStart));
    message.subscriptionEnd !== undefined && (obj.subscription_end = Math.round(message.subscriptionEnd));
    message.salesName !== undefined && (obj.sales_name = message.salesName);
    message.salesNumber !== undefined && (obj.sales_number = message.salesNumber);
    message.salesEmail !== undefined && (obj.sales_email = message.salesEmail);
    return obj;
  },

  create<I extends Exact<DeepPartial<EnterpriseModel>, I>>(base?: I): EnterpriseModel {
    return EnterpriseModel.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<EnterpriseModel>, I>>(object: I): EnterpriseModel {
    const message = createBaseEnterpriseModel();
    message.euuid = object.euuid ?? "";
    message.name = object.name ?? "";
    message.owner = object.owner ?? "";
    message.numSeats = object.numSeats ?? 0;
    message.numFilledSeats = object.numFilledSeats ?? 0;
    message.createdAt = object.createdAt ?? 0;
    message.updatedAt = object.updatedAt ?? 0;
    message.admins = object.admins?.map((e) => e) || [];
    message.subscriptionStart = object.subscriptionStart ?? 0;
    message.subscriptionEnd = object.subscriptionEnd ?? 0;
    message.salesName = object.salesName ?? "";
    message.salesNumber = object.salesNumber ?? "";
    message.salesEmail = object.salesEmail ?? "";
    return message;
  },
};

function createBaseEmptyResponse(): EmptyResponse {
  return {};
}

export const EmptyResponse = {
  encode(_: EmptyResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EmptyResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEmptyResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): EmptyResponse {
    return {};
  },

  toJSON(_: EmptyResponse): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<EmptyResponse>, I>>(base?: I): EmptyResponse {
    return EmptyResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<EmptyResponse>, I>>(_: I): EmptyResponse {
    const message = createBaseEmptyResponse();
    return message;
  },
};

function createBaseCreateEnterpriseRequest(): CreateEnterpriseRequest {
  return {
    name: "",
    owner: "",
    numSeats: 0,
    subscriptionStart: 0,
    subscriptionEnd: 0,
    salesName: "",
    salesNumber: "",
    salesEmail: "",
  };
}

export const CreateEnterpriseRequest = {
  encode(message: CreateEnterpriseRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.owner !== "") {
      writer.uint32(18).string(message.owner);
    }
    if (message.numSeats !== 0) {
      writer.uint32(24).int64(message.numSeats);
    }
    if (message.subscriptionStart !== 0) {
      writer.uint32(32).int64(message.subscriptionStart);
    }
    if (message.subscriptionEnd !== 0) {
      writer.uint32(40).int64(message.subscriptionEnd);
    }
    if (message.salesName !== "") {
      writer.uint32(50).string(message.salesName);
    }
    if (message.salesNumber !== "") {
      writer.uint32(58).string(message.salesNumber);
    }
    if (message.salesEmail !== "") {
      writer.uint32(66).string(message.salesEmail);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateEnterpriseRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateEnterpriseRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.owner = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.numSeats = longToNumber(reader.int64() as Long);
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.subscriptionStart = longToNumber(reader.int64() as Long);
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.subscriptionEnd = longToNumber(reader.int64() as Long);
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.salesName = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.salesNumber = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.salesEmail = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateEnterpriseRequest {
    return {
      name: isSet(object.name) ? String(object.name) : "",
      owner: isSet(object.owner) ? String(object.owner) : "",
      numSeats: isSet(object.num_seats) ? Number(object.num_seats) : 0,
      subscriptionStart: isSet(object.subscription_start) ? Number(object.subscription_start) : 0,
      subscriptionEnd: isSet(object.subscription_end) ? Number(object.subscription_end) : 0,
      salesName: isSet(object.sales_name) ? String(object.sales_name) : "",
      salesNumber: isSet(object.sales_number) ? String(object.sales_number) : "",
      salesEmail: isSet(object.sales_email) ? String(object.sales_email) : "",
    };
  },

  toJSON(message: CreateEnterpriseRequest): unknown {
    const obj: any = {};
    message.name !== undefined && (obj.name = message.name);
    message.owner !== undefined && (obj.owner = message.owner);
    message.numSeats !== undefined && (obj.num_seats = Math.round(message.numSeats));
    message.subscriptionStart !== undefined && (obj.subscription_start = Math.round(message.subscriptionStart));
    message.subscriptionEnd !== undefined && (obj.subscription_end = Math.round(message.subscriptionEnd));
    message.salesName !== undefined && (obj.sales_name = message.salesName);
    message.salesNumber !== undefined && (obj.sales_number = message.salesNumber);
    message.salesEmail !== undefined && (obj.sales_email = message.salesEmail);
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateEnterpriseRequest>, I>>(base?: I): CreateEnterpriseRequest {
    return CreateEnterpriseRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<CreateEnterpriseRequest>, I>>(object: I): CreateEnterpriseRequest {
    const message = createBaseCreateEnterpriseRequest();
    message.name = object.name ?? "";
    message.owner = object.owner ?? "";
    message.numSeats = object.numSeats ?? 0;
    message.subscriptionStart = object.subscriptionStart ?? 0;
    message.subscriptionEnd = object.subscriptionEnd ?? 0;
    message.salesName = object.salesName ?? "";
    message.salesNumber = object.salesNumber ?? "";
    message.salesEmail = object.salesEmail ?? "";
    return message;
  },
};

function createBaseEditEnterpriseRequest(): EditEnterpriseRequest {
  return {
    euuid: "",
    name: "",
    owner: "",
    numSeats: 0,
    numFilledSeats: 0,
    subscriptionStart: 0,
    subscriptionEnd: 0,
    admins: [],
  };
}

export const EditEnterpriseRequest = {
  encode(message: EditEnterpriseRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.euuid !== "") {
      writer.uint32(10).string(message.euuid);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.owner !== "") {
      writer.uint32(26).string(message.owner);
    }
    if (message.numSeats !== 0) {
      writer.uint32(32).int64(message.numSeats);
    }
    if (message.numFilledSeats !== 0) {
      writer.uint32(40).int64(message.numFilledSeats);
    }
    if (message.subscriptionStart !== 0) {
      writer.uint32(48).int64(message.subscriptionStart);
    }
    if (message.subscriptionEnd !== 0) {
      writer.uint32(56).int64(message.subscriptionEnd);
    }
    for (const v of message.admins) {
      writer.uint32(66).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EditEnterpriseRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEditEnterpriseRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.euuid = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.owner = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.numSeats = longToNumber(reader.int64() as Long);
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.numFilledSeats = longToNumber(reader.int64() as Long);
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.subscriptionStart = longToNumber(reader.int64() as Long);
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.subscriptionEnd = longToNumber(reader.int64() as Long);
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.admins.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EditEnterpriseRequest {
    return {
      euuid: isSet(object.euuid) ? String(object.euuid) : "",
      name: isSet(object.name) ? String(object.name) : "",
      owner: isSet(object.owner) ? String(object.owner) : "",
      numSeats: isSet(object.num_seats) ? Number(object.num_seats) : 0,
      numFilledSeats: isSet(object.num_filled_seats) ? Number(object.num_filled_seats) : 0,
      subscriptionStart: isSet(object.subscription_start) ? Number(object.subscription_start) : 0,
      subscriptionEnd: isSet(object.subscription_end) ? Number(object.subscription_end) : 0,
      admins: Array.isArray(object?.admins) ? object.admins.map((e: any) => String(e)) : [],
    };
  },

  toJSON(message: EditEnterpriseRequest): unknown {
    const obj: any = {};
    message.euuid !== undefined && (obj.euuid = message.euuid);
    message.name !== undefined && (obj.name = message.name);
    message.owner !== undefined && (obj.owner = message.owner);
    message.numSeats !== undefined && (obj.num_seats = Math.round(message.numSeats));
    message.numFilledSeats !== undefined && (obj.num_filled_seats = Math.round(message.numFilledSeats));
    message.subscriptionStart !== undefined && (obj.subscription_start = Math.round(message.subscriptionStart));
    message.subscriptionEnd !== undefined && (obj.subscription_end = Math.round(message.subscriptionEnd));
    if (message.admins) {
      obj.admins = message.admins.map((e) => e);
    } else {
      obj.admins = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EditEnterpriseRequest>, I>>(base?: I): EditEnterpriseRequest {
    return EditEnterpriseRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<EditEnterpriseRequest>, I>>(object: I): EditEnterpriseRequest {
    const message = createBaseEditEnterpriseRequest();
    message.euuid = object.euuid ?? "";
    message.name = object.name ?? "";
    message.owner = object.owner ?? "";
    message.numSeats = object.numSeats ?? 0;
    message.numFilledSeats = object.numFilledSeats ?? 0;
    message.subscriptionStart = object.subscriptionStart ?? 0;
    message.subscriptionEnd = object.subscriptionEnd ?? 0;
    message.admins = object.admins?.map((e) => e) || [];
    return message;
  },
};

function createBaseGetEnterpriseFromUserIDRequest(): GetEnterpriseFromUserIDRequest {
  return { uuid: "" };
}

export const GetEnterpriseFromUserIDRequest = {
  encode(message: GetEnterpriseFromUserIDRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.uuid !== "") {
      writer.uint32(10).string(message.uuid);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetEnterpriseFromUserIDRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetEnterpriseFromUserIDRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.uuid = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetEnterpriseFromUserIDRequest {
    return { uuid: isSet(object.uuid) ? String(object.uuid) : "" };
  },

  toJSON(message: GetEnterpriseFromUserIDRequest): unknown {
    const obj: any = {};
    message.uuid !== undefined && (obj.uuid = message.uuid);
    return obj;
  },

  create<I extends Exact<DeepPartial<GetEnterpriseFromUserIDRequest>, I>>(base?: I): GetEnterpriseFromUserIDRequest {
    return GetEnterpriseFromUserIDRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GetEnterpriseFromUserIDRequest>, I>>(
    object: I,
  ): GetEnterpriseFromUserIDRequest {
    const message = createBaseGetEnterpriseFromUserIDRequest();
    message.uuid = object.uuid ?? "";
    return message;
  },
};

function createBaseGetEnterpriseRequest(): GetEnterpriseRequest {
  return { euuid: "" };
}

export const GetEnterpriseRequest = {
  encode(message: GetEnterpriseRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.euuid !== "") {
      writer.uint32(10).string(message.euuid);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetEnterpriseRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetEnterpriseRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.euuid = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetEnterpriseRequest {
    return { euuid: isSet(object.euuid) ? String(object.euuid) : "" };
  },

  toJSON(message: GetEnterpriseRequest): unknown {
    const obj: any = {};
    message.euuid !== undefined && (obj.euuid = message.euuid);
    return obj;
  },

  create<I extends Exact<DeepPartial<GetEnterpriseRequest>, I>>(base?: I): GetEnterpriseRequest {
    return GetEnterpriseRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GetEnterpriseRequest>, I>>(object: I): GetEnterpriseRequest {
    const message = createBaseGetEnterpriseRequest();
    message.euuid = object.euuid ?? "";
    return message;
  },
};

function createBaseUpdateEnterpriseMembershipRequest(): UpdateEnterpriseMembershipRequest {
  return { euuid: "", onboarding: 0 };
}

export const UpdateEnterpriseMembershipRequest = {
  encode(message: UpdateEnterpriseMembershipRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.euuid !== "") {
      writer.uint32(10).string(message.euuid);
    }
    if (message.onboarding !== 0) {
      writer.uint32(16).int32(message.onboarding);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateEnterpriseMembershipRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateEnterpriseMembershipRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.euuid = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.onboarding = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateEnterpriseMembershipRequest {
    return {
      euuid: isSet(object.euuid) ? String(object.euuid) : "",
      onboarding: isSet(object.onboarding) ? booleanFromJSON(object.onboarding) : 0,
    };
  },

  toJSON(message: UpdateEnterpriseMembershipRequest): unknown {
    const obj: any = {};
    message.euuid !== undefined && (obj.euuid = message.euuid);
    message.onboarding !== undefined && (obj.onboarding = booleanToJSON(message.onboarding));
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateEnterpriseMembershipRequest>, I>>(
    base?: I,
  ): UpdateEnterpriseMembershipRequest {
    return UpdateEnterpriseMembershipRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UpdateEnterpriseMembershipRequest>, I>>(
    object: I,
  ): UpdateEnterpriseMembershipRequest {
    const message = createBaseUpdateEnterpriseMembershipRequest();
    message.euuid = object.euuid ?? "";
    message.onboarding = object.onboarding ?? 0;
    return message;
  },
};

export interface ApolloService {
  CreateEnterprise(request: CreateEnterpriseRequest): Promise<EnterpriseModel>;
  GetEnterpriseFromUserID(request: GetEnterpriseFromUserIDRequest): Promise<EnterpriseModel>;
  GetEnterprise(request: GetEnterpriseRequest): Promise<EnterpriseModel>;
  EditEnterprise(request: EditEnterpriseRequest): Promise<EmptyResponse>;
  UpdateEnterpriseMembership(request: UpdateEnterpriseMembershipRequest): Promise<EmptyResponse>;
}

export class ApolloServiceClientImpl implements ApolloService {
  private readonly rpc: Rpc;
  private readonly service: string;
  constructor(rpc: Rpc, opts?: { service?: string }) {
    this.service = opts?.service || "apollo.ApolloService";
    this.rpc = rpc;
    this.CreateEnterprise = this.CreateEnterprise.bind(this);
    this.GetEnterpriseFromUserID = this.GetEnterpriseFromUserID.bind(this);
    this.GetEnterprise = this.GetEnterprise.bind(this);
    this.EditEnterprise = this.EditEnterprise.bind(this);
    this.UpdateEnterpriseMembership = this.UpdateEnterpriseMembership.bind(this);
  }
  CreateEnterprise(request: CreateEnterpriseRequest): Promise<EnterpriseModel> {
    const data = CreateEnterpriseRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "CreateEnterprise", data);
    return promise.then((data) => EnterpriseModel.decode(_m0.Reader.create(data)));
  }

  GetEnterpriseFromUserID(request: GetEnterpriseFromUserIDRequest): Promise<EnterpriseModel> {
    const data = GetEnterpriseFromUserIDRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "GetEnterpriseFromUserID", data);
    return promise.then((data) => EnterpriseModel.decode(_m0.Reader.create(data)));
  }

  GetEnterprise(request: GetEnterpriseRequest): Promise<EnterpriseModel> {
    const data = GetEnterpriseRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "GetEnterprise", data);
    return promise.then((data) => EnterpriseModel.decode(_m0.Reader.create(data)));
  }

  EditEnterprise(request: EditEnterpriseRequest): Promise<EmptyResponse> {
    const data = EditEnterpriseRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "EditEnterprise", data);
    return promise.then((data) => EmptyResponse.decode(_m0.Reader.create(data)));
  }

  UpdateEnterpriseMembership(request: UpdateEnterpriseMembershipRequest): Promise<EmptyResponse> {
    const data = UpdateEnterpriseMembershipRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "UpdateEnterpriseMembership", data);
    return promise.then((data) => EmptyResponse.decode(_m0.Reader.create(data)));
  }
}

interface Rpc {
  request(service: string, method: string, data: Uint8Array): Promise<Uint8Array>;
}

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var tsProtoGlobalThis: any = (() => {
  if (typeof globalThis !== "undefined") {
    return globalThis;
  }
  if (typeof self !== "undefined") {
    return self;
  }
  if (typeof window !== "undefined") {
    return window;
  }
  if (typeof global !== "undefined") {
    return global;
  }
  throw "Unable to locate global object";
})();

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new tsProtoGlobalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
