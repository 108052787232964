/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";
import { Boolean, booleanFromJSON, booleanToJSON } from "../../../common/pb/common";

export const protobufPackage = "hephaestus";

export enum LetterStatus {
  STATUS_UNKNOWN = 0,
  STATUS_LOADING = 1,
  STATUS_CREATED = 2,
  STATUS_FAILED = 3,
  UNRECOGNIZED = -1,
}

export function letterStatusFromJSON(object: any): LetterStatus {
  switch (object) {
    case 0:
    case "STATUS_UNKNOWN":
      return LetterStatus.STATUS_UNKNOWN;
    case 1:
    case "STATUS_LOADING":
      return LetterStatus.STATUS_LOADING;
    case 2:
    case "STATUS_CREATED":
      return LetterStatus.STATUS_CREATED;
    case 3:
    case "STATUS_FAILED":
      return LetterStatus.STATUS_FAILED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return LetterStatus.UNRECOGNIZED;
  }
}

export function letterStatusToJSON(object: LetterStatus): number {
  switch (object) {
    case LetterStatus.STATUS_UNKNOWN:
      return 0;
    case LetterStatus.STATUS_LOADING:
      return 1;
    case LetterStatus.STATUS_CREATED:
      return 2;
    case LetterStatus.STATUS_FAILED:
      return 3;
    case LetterStatus.UNRECOGNIZED:
    default:
      return -1;
  }
}

export interface EmptyResponse {
}

export interface LetterModel {
  luuid: string;
  wuuid: string;
  /** time at letter generation start */
  createdAt: number;
  /** time at letter generation complete */
  generatedAt: number;
  letter: string;
  status: LetterStatus;
  ruuid: string;
}

export interface CreateLetterRequest {
  wuuid: string;
  ruuid: string;
  writerName: string;
  responses: string[];
  isWriteNow: Boolean;
  boost: string;
  comments: string;
  instructions: string;
}

export interface GetLetterRequest {
  /** letterid or ruuid */
  identifier: string;
}

export interface EditLetterRequest {
  luuid: string;
  letter: LetterModel | undefined;
}

export interface RegenerateLetterRequest {
  wuuid: string;
  ruuid: string;
  writerName: string;
  responses: string[];
  isWriteNow: Boolean;
  luuid: string;
  boost: string;
  comments: string;
  instructions: string;
}

export interface DeleteLetterRequest {
  luuid: string;
}

export interface GeneratedBragsheetModel {
  luuid: string;
  auuid: string;
  /** time at letter generation start */
  createdAt: number;
  /** time at letter generation complete */
  generatedAt: number;
  letter: string;
  email: string;
  bragsheet: string;
  status: LetterStatus;
  buuid: string;
}

export interface CreateGeneratedBragsheetRequest {
  auuid: string;
  applicant: string;
  writer: string;
  metadata: string[];
  buuid: string;
}

export interface CreateGeneratedBragsheetResponse {
  luuid: string;
}

export interface RegenerateGeneratedBragsheetRequest {
  auuid: string;
  applicant: string;
  writer: string;
  metadata: string[];
  buuid: string;
  luuid: string;
}

export interface RegenerateGeneratedBragsheetResponse {
}

export interface GetGeneratedBragsheetRequest {
  luuid: string;
}

export interface GetGeneratedBragsheetResponse {
  bragsheet: GeneratedBragsheetModel | undefined;
}

function createBaseEmptyResponse(): EmptyResponse {
  return {};
}

export const EmptyResponse = {
  encode(_: EmptyResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EmptyResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEmptyResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): EmptyResponse {
    return {};
  },

  toJSON(_: EmptyResponse): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<EmptyResponse>, I>>(base?: I): EmptyResponse {
    return EmptyResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<EmptyResponse>, I>>(_: I): EmptyResponse {
    const message = createBaseEmptyResponse();
    return message;
  },
};

function createBaseLetterModel(): LetterModel {
  return { luuid: "", wuuid: "", createdAt: 0, generatedAt: 0, letter: "", status: 0, ruuid: "" };
}

export const LetterModel = {
  encode(message: LetterModel, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.luuid !== "") {
      writer.uint32(10).string(message.luuid);
    }
    if (message.wuuid !== "") {
      writer.uint32(18).string(message.wuuid);
    }
    if (message.createdAt !== 0) {
      writer.uint32(24).int64(message.createdAt);
    }
    if (message.generatedAt !== 0) {
      writer.uint32(32).int64(message.generatedAt);
    }
    if (message.letter !== "") {
      writer.uint32(42).string(message.letter);
    }
    if (message.status !== 0) {
      writer.uint32(48).int32(message.status);
    }
    if (message.ruuid !== "") {
      writer.uint32(58).string(message.ruuid);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LetterModel {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLetterModel();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.luuid = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.wuuid = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.createdAt = longToNumber(reader.int64() as Long);
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.generatedAt = longToNumber(reader.int64() as Long);
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.letter = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.ruuid = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LetterModel {
    return {
      luuid: isSet(object.luuid) ? String(object.luuid) : "",
      wuuid: isSet(object.wuuid) ? String(object.wuuid) : "",
      createdAt: isSet(object.created_at) ? Number(object.created_at) : 0,
      generatedAt: isSet(object.generated_at) ? Number(object.generated_at) : 0,
      letter: isSet(object.letter) ? String(object.letter) : "",
      status: isSet(object.status) ? letterStatusFromJSON(object.status) : 0,
      ruuid: isSet(object.ruuid) ? String(object.ruuid) : "",
    };
  },

  toJSON(message: LetterModel): unknown {
    const obj: any = {};
    message.luuid !== undefined && (obj.luuid = message.luuid);
    message.wuuid !== undefined && (obj.wuuid = message.wuuid);
    message.createdAt !== undefined && (obj.created_at = Math.round(message.createdAt));
    message.generatedAt !== undefined && (obj.generated_at = Math.round(message.generatedAt));
    message.letter !== undefined && (obj.letter = message.letter);
    message.status !== undefined && (obj.status = letterStatusToJSON(message.status));
    message.ruuid !== undefined && (obj.ruuid = message.ruuid);
    return obj;
  },

  create<I extends Exact<DeepPartial<LetterModel>, I>>(base?: I): LetterModel {
    return LetterModel.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<LetterModel>, I>>(object: I): LetterModel {
    const message = createBaseLetterModel();
    message.luuid = object.luuid ?? "";
    message.wuuid = object.wuuid ?? "";
    message.createdAt = object.createdAt ?? 0;
    message.generatedAt = object.generatedAt ?? 0;
    message.letter = object.letter ?? "";
    message.status = object.status ?? 0;
    message.ruuid = object.ruuid ?? "";
    return message;
  },
};

function createBaseCreateLetterRequest(): CreateLetterRequest {
  return {
    wuuid: "",
    ruuid: "",
    writerName: "",
    responses: [],
    isWriteNow: 0,
    boost: "",
    comments: "",
    instructions: "",
  };
}

export const CreateLetterRequest = {
  encode(message: CreateLetterRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.wuuid !== "") {
      writer.uint32(10).string(message.wuuid);
    }
    if (message.ruuid !== "") {
      writer.uint32(18).string(message.ruuid);
    }
    if (message.writerName !== "") {
      writer.uint32(26).string(message.writerName);
    }
    for (const v of message.responses) {
      writer.uint32(34).string(v!);
    }
    if (message.isWriteNow !== 0) {
      writer.uint32(40).int32(message.isWriteNow);
    }
    if (message.boost !== "") {
      writer.uint32(50).string(message.boost);
    }
    if (message.comments !== "") {
      writer.uint32(58).string(message.comments);
    }
    if (message.instructions !== "") {
      writer.uint32(66).string(message.instructions);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateLetterRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateLetterRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.wuuid = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.ruuid = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.writerName = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.responses.push(reader.string());
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.isWriteNow = reader.int32() as any;
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.boost = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.comments = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.instructions = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateLetterRequest {
    return {
      wuuid: isSet(object.wuuid) ? String(object.wuuid) : "",
      ruuid: isSet(object.ruuid) ? String(object.ruuid) : "",
      writerName: isSet(object.writer_name) ? String(object.writer_name) : "",
      responses: Array.isArray(object?.responses) ? object.responses.map((e: any) => String(e)) : [],
      isWriteNow: isSet(object.is_write_now) ? booleanFromJSON(object.is_write_now) : 0,
      boost: isSet(object.boost) ? String(object.boost) : "",
      comments: isSet(object.comments) ? String(object.comments) : "",
      instructions: isSet(object.instructions) ? String(object.instructions) : "",
    };
  },

  toJSON(message: CreateLetterRequest): unknown {
    const obj: any = {};
    message.wuuid !== undefined && (obj.wuuid = message.wuuid);
    message.ruuid !== undefined && (obj.ruuid = message.ruuid);
    message.writerName !== undefined && (obj.writer_name = message.writerName);
    if (message.responses) {
      obj.responses = message.responses.map((e) => e);
    } else {
      obj.responses = [];
    }
    message.isWriteNow !== undefined && (obj.is_write_now = booleanToJSON(message.isWriteNow));
    message.boost !== undefined && (obj.boost = message.boost);
    message.comments !== undefined && (obj.comments = message.comments);
    message.instructions !== undefined && (obj.instructions = message.instructions);
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateLetterRequest>, I>>(base?: I): CreateLetterRequest {
    return CreateLetterRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<CreateLetterRequest>, I>>(object: I): CreateLetterRequest {
    const message = createBaseCreateLetterRequest();
    message.wuuid = object.wuuid ?? "";
    message.ruuid = object.ruuid ?? "";
    message.writerName = object.writerName ?? "";
    message.responses = object.responses?.map((e) => e) || [];
    message.isWriteNow = object.isWriteNow ?? 0;
    message.boost = object.boost ?? "";
    message.comments = object.comments ?? "";
    message.instructions = object.instructions ?? "";
    return message;
  },
};

function createBaseGetLetterRequest(): GetLetterRequest {
  return { identifier: "" };
}

export const GetLetterRequest = {
  encode(message: GetLetterRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.identifier !== "") {
      writer.uint32(10).string(message.identifier);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetLetterRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetLetterRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.identifier = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetLetterRequest {
    return { identifier: isSet(object.identifier) ? String(object.identifier) : "" };
  },

  toJSON(message: GetLetterRequest): unknown {
    const obj: any = {};
    message.identifier !== undefined && (obj.identifier = message.identifier);
    return obj;
  },

  create<I extends Exact<DeepPartial<GetLetterRequest>, I>>(base?: I): GetLetterRequest {
    return GetLetterRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GetLetterRequest>, I>>(object: I): GetLetterRequest {
    const message = createBaseGetLetterRequest();
    message.identifier = object.identifier ?? "";
    return message;
  },
};

function createBaseEditLetterRequest(): EditLetterRequest {
  return { luuid: "", letter: undefined };
}

export const EditLetterRequest = {
  encode(message: EditLetterRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.luuid !== "") {
      writer.uint32(10).string(message.luuid);
    }
    if (message.letter !== undefined) {
      LetterModel.encode(message.letter, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EditLetterRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEditLetterRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.luuid = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.letter = LetterModel.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EditLetterRequest {
    return {
      luuid: isSet(object.luuid) ? String(object.luuid) : "",
      letter: isSet(object.letter) ? LetterModel.fromJSON(object.letter) : undefined,
    };
  },

  toJSON(message: EditLetterRequest): unknown {
    const obj: any = {};
    message.luuid !== undefined && (obj.luuid = message.luuid);
    message.letter !== undefined && (obj.letter = message.letter ? LetterModel.toJSON(message.letter) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<EditLetterRequest>, I>>(base?: I): EditLetterRequest {
    return EditLetterRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<EditLetterRequest>, I>>(object: I): EditLetterRequest {
    const message = createBaseEditLetterRequest();
    message.luuid = object.luuid ?? "";
    message.letter = (object.letter !== undefined && object.letter !== null)
      ? LetterModel.fromPartial(object.letter)
      : undefined;
    return message;
  },
};

function createBaseRegenerateLetterRequest(): RegenerateLetterRequest {
  return {
    wuuid: "",
    ruuid: "",
    writerName: "",
    responses: [],
    isWriteNow: 0,
    luuid: "",
    boost: "",
    comments: "",
    instructions: "",
  };
}

export const RegenerateLetterRequest = {
  encode(message: RegenerateLetterRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.wuuid !== "") {
      writer.uint32(10).string(message.wuuid);
    }
    if (message.ruuid !== "") {
      writer.uint32(18).string(message.ruuid);
    }
    if (message.writerName !== "") {
      writer.uint32(26).string(message.writerName);
    }
    for (const v of message.responses) {
      writer.uint32(34).string(v!);
    }
    if (message.isWriteNow !== 0) {
      writer.uint32(40).int32(message.isWriteNow);
    }
    if (message.luuid !== "") {
      writer.uint32(50).string(message.luuid);
    }
    if (message.boost !== "") {
      writer.uint32(58).string(message.boost);
    }
    if (message.comments !== "") {
      writer.uint32(66).string(message.comments);
    }
    if (message.instructions !== "") {
      writer.uint32(74).string(message.instructions);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RegenerateLetterRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRegenerateLetterRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.wuuid = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.ruuid = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.writerName = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.responses.push(reader.string());
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.isWriteNow = reader.int32() as any;
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.luuid = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.boost = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.comments = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.instructions = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RegenerateLetterRequest {
    return {
      wuuid: isSet(object.wuuid) ? String(object.wuuid) : "",
      ruuid: isSet(object.ruuid) ? String(object.ruuid) : "",
      writerName: isSet(object.writer_name) ? String(object.writer_name) : "",
      responses: Array.isArray(object?.responses) ? object.responses.map((e: any) => String(e)) : [],
      isWriteNow: isSet(object.is_write_now) ? booleanFromJSON(object.is_write_now) : 0,
      luuid: isSet(object.luuid) ? String(object.luuid) : "",
      boost: isSet(object.boost) ? String(object.boost) : "",
      comments: isSet(object.comments) ? String(object.comments) : "",
      instructions: isSet(object.instructions) ? String(object.instructions) : "",
    };
  },

  toJSON(message: RegenerateLetterRequest): unknown {
    const obj: any = {};
    message.wuuid !== undefined && (obj.wuuid = message.wuuid);
    message.ruuid !== undefined && (obj.ruuid = message.ruuid);
    message.writerName !== undefined && (obj.writer_name = message.writerName);
    if (message.responses) {
      obj.responses = message.responses.map((e) => e);
    } else {
      obj.responses = [];
    }
    message.isWriteNow !== undefined && (obj.is_write_now = booleanToJSON(message.isWriteNow));
    message.luuid !== undefined && (obj.luuid = message.luuid);
    message.boost !== undefined && (obj.boost = message.boost);
    message.comments !== undefined && (obj.comments = message.comments);
    message.instructions !== undefined && (obj.instructions = message.instructions);
    return obj;
  },

  create<I extends Exact<DeepPartial<RegenerateLetterRequest>, I>>(base?: I): RegenerateLetterRequest {
    return RegenerateLetterRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<RegenerateLetterRequest>, I>>(object: I): RegenerateLetterRequest {
    const message = createBaseRegenerateLetterRequest();
    message.wuuid = object.wuuid ?? "";
    message.ruuid = object.ruuid ?? "";
    message.writerName = object.writerName ?? "";
    message.responses = object.responses?.map((e) => e) || [];
    message.isWriteNow = object.isWriteNow ?? 0;
    message.luuid = object.luuid ?? "";
    message.boost = object.boost ?? "";
    message.comments = object.comments ?? "";
    message.instructions = object.instructions ?? "";
    return message;
  },
};

function createBaseDeleteLetterRequest(): DeleteLetterRequest {
  return { luuid: "" };
}

export const DeleteLetterRequest = {
  encode(message: DeleteLetterRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.luuid !== "") {
      writer.uint32(10).string(message.luuid);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteLetterRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteLetterRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.luuid = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeleteLetterRequest {
    return { luuid: isSet(object.luuid) ? String(object.luuid) : "" };
  },

  toJSON(message: DeleteLetterRequest): unknown {
    const obj: any = {};
    message.luuid !== undefined && (obj.luuid = message.luuid);
    return obj;
  },

  create<I extends Exact<DeepPartial<DeleteLetterRequest>, I>>(base?: I): DeleteLetterRequest {
    return DeleteLetterRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DeleteLetterRequest>, I>>(object: I): DeleteLetterRequest {
    const message = createBaseDeleteLetterRequest();
    message.luuid = object.luuid ?? "";
    return message;
  },
};

function createBaseGeneratedBragsheetModel(): GeneratedBragsheetModel {
  return {
    luuid: "",
    auuid: "",
    createdAt: 0,
    generatedAt: 0,
    letter: "",
    email: "",
    bragsheet: "",
    status: 0,
    buuid: "",
  };
}

export const GeneratedBragsheetModel = {
  encode(message: GeneratedBragsheetModel, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.luuid !== "") {
      writer.uint32(10).string(message.luuid);
    }
    if (message.auuid !== "") {
      writer.uint32(18).string(message.auuid);
    }
    if (message.createdAt !== 0) {
      writer.uint32(24).int64(message.createdAt);
    }
    if (message.generatedAt !== 0) {
      writer.uint32(32).int64(message.generatedAt);
    }
    if (message.letter !== "") {
      writer.uint32(42).string(message.letter);
    }
    if (message.email !== "") {
      writer.uint32(50).string(message.email);
    }
    if (message.bragsheet !== "") {
      writer.uint32(58).string(message.bragsheet);
    }
    if (message.status !== 0) {
      writer.uint32(64).int32(message.status);
    }
    if (message.buuid !== "") {
      writer.uint32(74).string(message.buuid);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GeneratedBragsheetModel {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGeneratedBragsheetModel();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.luuid = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.auuid = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.createdAt = longToNumber(reader.int64() as Long);
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.generatedAt = longToNumber(reader.int64() as Long);
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.letter = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.email = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.bragsheet = reader.string();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.buuid = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GeneratedBragsheetModel {
    return {
      luuid: isSet(object.luuid) ? String(object.luuid) : "",
      auuid: isSet(object.auuid) ? String(object.auuid) : "",
      createdAt: isSet(object.created_at) ? Number(object.created_at) : 0,
      generatedAt: isSet(object.generated_at) ? Number(object.generated_at) : 0,
      letter: isSet(object.letter) ? String(object.letter) : "",
      email: isSet(object.email) ? String(object.email) : "",
      bragsheet: isSet(object.bragsheet) ? String(object.bragsheet) : "",
      status: isSet(object.status) ? letterStatusFromJSON(object.status) : 0,
      buuid: isSet(object.buuid) ? String(object.buuid) : "",
    };
  },

  toJSON(message: GeneratedBragsheetModel): unknown {
    const obj: any = {};
    message.luuid !== undefined && (obj.luuid = message.luuid);
    message.auuid !== undefined && (obj.auuid = message.auuid);
    message.createdAt !== undefined && (obj.created_at = Math.round(message.createdAt));
    message.generatedAt !== undefined && (obj.generated_at = Math.round(message.generatedAt));
    message.letter !== undefined && (obj.letter = message.letter);
    message.email !== undefined && (obj.email = message.email);
    message.bragsheet !== undefined && (obj.bragsheet = message.bragsheet);
    message.status !== undefined && (obj.status = letterStatusToJSON(message.status));
    message.buuid !== undefined && (obj.buuid = message.buuid);
    return obj;
  },

  create<I extends Exact<DeepPartial<GeneratedBragsheetModel>, I>>(base?: I): GeneratedBragsheetModel {
    return GeneratedBragsheetModel.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GeneratedBragsheetModel>, I>>(object: I): GeneratedBragsheetModel {
    const message = createBaseGeneratedBragsheetModel();
    message.luuid = object.luuid ?? "";
    message.auuid = object.auuid ?? "";
    message.createdAt = object.createdAt ?? 0;
    message.generatedAt = object.generatedAt ?? 0;
    message.letter = object.letter ?? "";
    message.email = object.email ?? "";
    message.bragsheet = object.bragsheet ?? "";
    message.status = object.status ?? 0;
    message.buuid = object.buuid ?? "";
    return message;
  },
};

function createBaseCreateGeneratedBragsheetRequest(): CreateGeneratedBragsheetRequest {
  return { auuid: "", applicant: "", writer: "", metadata: [], buuid: "" };
}

export const CreateGeneratedBragsheetRequest = {
  encode(message: CreateGeneratedBragsheetRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.auuid !== "") {
      writer.uint32(10).string(message.auuid);
    }
    if (message.applicant !== "") {
      writer.uint32(18).string(message.applicant);
    }
    if (message.writer !== "") {
      writer.uint32(26).string(message.writer);
    }
    for (const v of message.metadata) {
      writer.uint32(34).string(v!);
    }
    if (message.buuid !== "") {
      writer.uint32(42).string(message.buuid);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateGeneratedBragsheetRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateGeneratedBragsheetRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.auuid = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.applicant = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.writer = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.metadata.push(reader.string());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.buuid = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateGeneratedBragsheetRequest {
    return {
      auuid: isSet(object.auuid) ? String(object.auuid) : "",
      applicant: isSet(object.applicant) ? String(object.applicant) : "",
      writer: isSet(object.writer) ? String(object.writer) : "",
      metadata: Array.isArray(object?.metadata) ? object.metadata.map((e: any) => String(e)) : [],
      buuid: isSet(object.buuid) ? String(object.buuid) : "",
    };
  },

  toJSON(message: CreateGeneratedBragsheetRequest): unknown {
    const obj: any = {};
    message.auuid !== undefined && (obj.auuid = message.auuid);
    message.applicant !== undefined && (obj.applicant = message.applicant);
    message.writer !== undefined && (obj.writer = message.writer);
    if (message.metadata) {
      obj.metadata = message.metadata.map((e) => e);
    } else {
      obj.metadata = [];
    }
    message.buuid !== undefined && (obj.buuid = message.buuid);
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateGeneratedBragsheetRequest>, I>>(base?: I): CreateGeneratedBragsheetRequest {
    return CreateGeneratedBragsheetRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<CreateGeneratedBragsheetRequest>, I>>(
    object: I,
  ): CreateGeneratedBragsheetRequest {
    const message = createBaseCreateGeneratedBragsheetRequest();
    message.auuid = object.auuid ?? "";
    message.applicant = object.applicant ?? "";
    message.writer = object.writer ?? "";
    message.metadata = object.metadata?.map((e) => e) || [];
    message.buuid = object.buuid ?? "";
    return message;
  },
};

function createBaseCreateGeneratedBragsheetResponse(): CreateGeneratedBragsheetResponse {
  return { luuid: "" };
}

export const CreateGeneratedBragsheetResponse = {
  encode(message: CreateGeneratedBragsheetResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.luuid !== "") {
      writer.uint32(10).string(message.luuid);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateGeneratedBragsheetResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateGeneratedBragsheetResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.luuid = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateGeneratedBragsheetResponse {
    return { luuid: isSet(object.luuid) ? String(object.luuid) : "" };
  },

  toJSON(message: CreateGeneratedBragsheetResponse): unknown {
    const obj: any = {};
    message.luuid !== undefined && (obj.luuid = message.luuid);
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateGeneratedBragsheetResponse>, I>>(
    base?: I,
  ): CreateGeneratedBragsheetResponse {
    return CreateGeneratedBragsheetResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<CreateGeneratedBragsheetResponse>, I>>(
    object: I,
  ): CreateGeneratedBragsheetResponse {
    const message = createBaseCreateGeneratedBragsheetResponse();
    message.luuid = object.luuid ?? "";
    return message;
  },
};

function createBaseRegenerateGeneratedBragsheetRequest(): RegenerateGeneratedBragsheetRequest {
  return { auuid: "", applicant: "", writer: "", metadata: [], buuid: "", luuid: "" };
}

export const RegenerateGeneratedBragsheetRequest = {
  encode(message: RegenerateGeneratedBragsheetRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.auuid !== "") {
      writer.uint32(10).string(message.auuid);
    }
    if (message.applicant !== "") {
      writer.uint32(18).string(message.applicant);
    }
    if (message.writer !== "") {
      writer.uint32(26).string(message.writer);
    }
    for (const v of message.metadata) {
      writer.uint32(34).string(v!);
    }
    if (message.buuid !== "") {
      writer.uint32(42).string(message.buuid);
    }
    if (message.luuid !== "") {
      writer.uint32(50).string(message.luuid);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RegenerateGeneratedBragsheetRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRegenerateGeneratedBragsheetRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.auuid = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.applicant = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.writer = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.metadata.push(reader.string());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.buuid = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.luuid = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RegenerateGeneratedBragsheetRequest {
    return {
      auuid: isSet(object.auuid) ? String(object.auuid) : "",
      applicant: isSet(object.applicant) ? String(object.applicant) : "",
      writer: isSet(object.writer) ? String(object.writer) : "",
      metadata: Array.isArray(object?.metadata) ? object.metadata.map((e: any) => String(e)) : [],
      buuid: isSet(object.buuid) ? String(object.buuid) : "",
      luuid: isSet(object.luuid) ? String(object.luuid) : "",
    };
  },

  toJSON(message: RegenerateGeneratedBragsheetRequest): unknown {
    const obj: any = {};
    message.auuid !== undefined && (obj.auuid = message.auuid);
    message.applicant !== undefined && (obj.applicant = message.applicant);
    message.writer !== undefined && (obj.writer = message.writer);
    if (message.metadata) {
      obj.metadata = message.metadata.map((e) => e);
    } else {
      obj.metadata = [];
    }
    message.buuid !== undefined && (obj.buuid = message.buuid);
    message.luuid !== undefined && (obj.luuid = message.luuid);
    return obj;
  },

  create<I extends Exact<DeepPartial<RegenerateGeneratedBragsheetRequest>, I>>(
    base?: I,
  ): RegenerateGeneratedBragsheetRequest {
    return RegenerateGeneratedBragsheetRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<RegenerateGeneratedBragsheetRequest>, I>>(
    object: I,
  ): RegenerateGeneratedBragsheetRequest {
    const message = createBaseRegenerateGeneratedBragsheetRequest();
    message.auuid = object.auuid ?? "";
    message.applicant = object.applicant ?? "";
    message.writer = object.writer ?? "";
    message.metadata = object.metadata?.map((e) => e) || [];
    message.buuid = object.buuid ?? "";
    message.luuid = object.luuid ?? "";
    return message;
  },
};

function createBaseRegenerateGeneratedBragsheetResponse(): RegenerateGeneratedBragsheetResponse {
  return {};
}

export const RegenerateGeneratedBragsheetResponse = {
  encode(_: RegenerateGeneratedBragsheetResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RegenerateGeneratedBragsheetResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRegenerateGeneratedBragsheetResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): RegenerateGeneratedBragsheetResponse {
    return {};
  },

  toJSON(_: RegenerateGeneratedBragsheetResponse): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<RegenerateGeneratedBragsheetResponse>, I>>(
    base?: I,
  ): RegenerateGeneratedBragsheetResponse {
    return RegenerateGeneratedBragsheetResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<RegenerateGeneratedBragsheetResponse>, I>>(
    _: I,
  ): RegenerateGeneratedBragsheetResponse {
    const message = createBaseRegenerateGeneratedBragsheetResponse();
    return message;
  },
};

function createBaseGetGeneratedBragsheetRequest(): GetGeneratedBragsheetRequest {
  return { luuid: "" };
}

export const GetGeneratedBragsheetRequest = {
  encode(message: GetGeneratedBragsheetRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.luuid !== "") {
      writer.uint32(10).string(message.luuid);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetGeneratedBragsheetRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetGeneratedBragsheetRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.luuid = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetGeneratedBragsheetRequest {
    return { luuid: isSet(object.luuid) ? String(object.luuid) : "" };
  },

  toJSON(message: GetGeneratedBragsheetRequest): unknown {
    const obj: any = {};
    message.luuid !== undefined && (obj.luuid = message.luuid);
    return obj;
  },

  create<I extends Exact<DeepPartial<GetGeneratedBragsheetRequest>, I>>(base?: I): GetGeneratedBragsheetRequest {
    return GetGeneratedBragsheetRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GetGeneratedBragsheetRequest>, I>>(object: I): GetGeneratedBragsheetRequest {
    const message = createBaseGetGeneratedBragsheetRequest();
    message.luuid = object.luuid ?? "";
    return message;
  },
};

function createBaseGetGeneratedBragsheetResponse(): GetGeneratedBragsheetResponse {
  return { bragsheet: undefined };
}

export const GetGeneratedBragsheetResponse = {
  encode(message: GetGeneratedBragsheetResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.bragsheet !== undefined) {
      GeneratedBragsheetModel.encode(message.bragsheet, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetGeneratedBragsheetResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetGeneratedBragsheetResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.bragsheet = GeneratedBragsheetModel.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetGeneratedBragsheetResponse {
    return { bragsheet: isSet(object.bragsheet) ? GeneratedBragsheetModel.fromJSON(object.bragsheet) : undefined };
  },

  toJSON(message: GetGeneratedBragsheetResponse): unknown {
    const obj: any = {};
    message.bragsheet !== undefined &&
      (obj.bragsheet = message.bragsheet ? GeneratedBragsheetModel.toJSON(message.bragsheet) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<GetGeneratedBragsheetResponse>, I>>(base?: I): GetGeneratedBragsheetResponse {
    return GetGeneratedBragsheetResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GetGeneratedBragsheetResponse>, I>>(
    object: I,
  ): GetGeneratedBragsheetResponse {
    const message = createBaseGetGeneratedBragsheetResponse();
    message.bragsheet = (object.bragsheet !== undefined && object.bragsheet !== null)
      ? GeneratedBragsheetModel.fromPartial(object.bragsheet)
      : undefined;
    return message;
  },
};

export interface HephaestusService {
  CreateLetter(request: CreateLetterRequest): Promise<LetterModel>;
  GetLetter(request: GetLetterRequest): Promise<LetterModel>;
  RegenerateLetter(request: RegenerateLetterRequest): Promise<LetterModel>;
  DeleteLetter(request: DeleteLetterRequest): Promise<EmptyResponse>;
  EditLetter(request: LetterModel): Promise<EmptyResponse>;
  CreateGeneratedBragsheet(request: CreateGeneratedBragsheetRequest): Promise<CreateGeneratedBragsheetResponse>;
  RegenerateGeneratedBragsheet(
    request: RegenerateGeneratedBragsheetRequest,
  ): Promise<RegenerateGeneratedBragsheetResponse>;
  GetGeneratedBragsheet(request: GetGeneratedBragsheetRequest): Promise<GetGeneratedBragsheetResponse>;
}

export class HephaestusServiceClientImpl implements HephaestusService {
  private readonly rpc: Rpc;
  private readonly service: string;
  constructor(rpc: Rpc, opts?: { service?: string }) {
    this.service = opts?.service || "hephaestus.HephaestusService";
    this.rpc = rpc;
    this.CreateLetter = this.CreateLetter.bind(this);
    this.GetLetter = this.GetLetter.bind(this);
    this.RegenerateLetter = this.RegenerateLetter.bind(this);
    this.DeleteLetter = this.DeleteLetter.bind(this);
    this.EditLetter = this.EditLetter.bind(this);
    this.CreateGeneratedBragsheet = this.CreateGeneratedBragsheet.bind(this);
    this.RegenerateGeneratedBragsheet = this.RegenerateGeneratedBragsheet.bind(this);
    this.GetGeneratedBragsheet = this.GetGeneratedBragsheet.bind(this);
  }
  CreateLetter(request: CreateLetterRequest): Promise<LetterModel> {
    const data = CreateLetterRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "CreateLetter", data);
    return promise.then((data) => LetterModel.decode(_m0.Reader.create(data)));
  }

  GetLetter(request: GetLetterRequest): Promise<LetterModel> {
    const data = GetLetterRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "GetLetter", data);
    return promise.then((data) => LetterModel.decode(_m0.Reader.create(data)));
  }

  RegenerateLetter(request: RegenerateLetterRequest): Promise<LetterModel> {
    const data = RegenerateLetterRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "RegenerateLetter", data);
    return promise.then((data) => LetterModel.decode(_m0.Reader.create(data)));
  }

  DeleteLetter(request: DeleteLetterRequest): Promise<EmptyResponse> {
    const data = DeleteLetterRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "DeleteLetter", data);
    return promise.then((data) => EmptyResponse.decode(_m0.Reader.create(data)));
  }

  EditLetter(request: LetterModel): Promise<EmptyResponse> {
    const data = LetterModel.encode(request).finish();
    const promise = this.rpc.request(this.service, "EditLetter", data);
    return promise.then((data) => EmptyResponse.decode(_m0.Reader.create(data)));
  }

  CreateGeneratedBragsheet(request: CreateGeneratedBragsheetRequest): Promise<CreateGeneratedBragsheetResponse> {
    const data = CreateGeneratedBragsheetRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "CreateGeneratedBragsheet", data);
    return promise.then((data) => CreateGeneratedBragsheetResponse.decode(_m0.Reader.create(data)));
  }

  RegenerateGeneratedBragsheet(
    request: RegenerateGeneratedBragsheetRequest,
  ): Promise<RegenerateGeneratedBragsheetResponse> {
    const data = RegenerateGeneratedBragsheetRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "RegenerateGeneratedBragsheet", data);
    return promise.then((data) => RegenerateGeneratedBragsheetResponse.decode(_m0.Reader.create(data)));
  }

  GetGeneratedBragsheet(request: GetGeneratedBragsheetRequest): Promise<GetGeneratedBragsheetResponse> {
    const data = GetGeneratedBragsheetRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "GetGeneratedBragsheet", data);
    return promise.then((data) => GetGeneratedBragsheetResponse.decode(_m0.Reader.create(data)));
  }
}

interface Rpc {
  request(service: string, method: string, data: Uint8Array): Promise<Uint8Array>;
}

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var tsProtoGlobalThis: any = (() => {
  if (typeof globalThis !== "undefined") {
    return globalThis;
  }
  if (typeof self !== "undefined") {
    return self;
  }
  if (typeof window !== "undefined") {
    return window;
  }
  if (typeof global !== "undefined") {
    return global;
  }
  throw "Unable to locate global object";
})();

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new tsProtoGlobalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
