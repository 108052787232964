import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { genericHandler } from '../../reffy-api';
import {
    CreateWriterRequest,
    PrefixQueryResponse,
    UserModel,
    WriterModel,
} from '../../reffy-pb/artemis-svc/pkg/pb/artemis';
import { useResponsesQuery } from '../litae/litae_hooks';
import { GetWritersResponsesResponse, RequestStatus, ResponseModel } from '../../reffy-pb/litae-svc/pkg/pb/litae';

export type CombinedModel = UserModel & WriterModel & {
    responseStats: {}
    userType: 'Admin' | 'Owner' | 'User'
};

// Get's a UserModel using either the authenticated JWT or provided identifier
export function useUserQuery(identifier?: string) {
    const key = `user-${identifier ?? 'authenticated'}`;
    return useQuery({
        queryKey: [key],
        queryFn: async () => {
            return new Promise<UserModel>(async (resolve, reject) => {
                const response = await genericHandler(
                    'GET',
                    'include',
                    identifier ? `/artemis/user/${identifier}` : '/artemis/user'
                );
                if (!response || response.error) {
                    console.log("user query hook error response:", response)
                    reject(response?.payload ?? 'unable to fulfill request');
                } else {
                    const user = UserModel.fromJSON(response.payload);
                    resolve(user);
                }
            });
        },

        retry: 1,
        retryOnMount: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,

        onError: (err) => {
            console.error(err);
        },
    });
}

export function useGetAllByEnterpriseIdQuery(identifier?: string) {
    const key = `enterprise-users-${identifier ?? 'authenticated'}`;
    const queryClient = useQueryClient();
    return useQuery({
        queryKey: [key],
        queryFn: async () => {
            return new Promise<CombinedModel[]>(async (resolve, reject) => {
                const response = await genericHandler(
                    'GET',
                    'include',
                    identifier ? `/artemis/all-by-enterprise/${identifier}` : '/artemis/all-by-enterprise'
                );
                if (!response || response.error) {
                    reject(response?.payload ?? 'unable to fulfill request');
                } else {
                    const userArray = response.payload.users as UserModel[];
                    const writerArray = response.payload.writers as WriterModel[];
                    const mergedPayload: CombinedModel[] = [];
                    for (let i = 0; i < userArray.length; i++) {
                        const userModel = UserModel.fromJSON(userArray[i]);
                        const writerModel = WriterModel.fromJSON(writerArray[i]);
                        if (userModel.uuid === "") {
                            continue;
                        }
                        const userAndWriterModel = Object.assign(writerModel, userModel);
                        const rawResponses = await genericHandler('GET', 'include', `/litae/responses/${userModel.uuid}`);
                        let userResponses = GetWritersResponsesResponse.fromJSON(rawResponses.payload).responses;

                        userResponses = userResponses.filter(resp => resp.ruuid && resp.ruuid !== '');
                        const responseStats = userResponses.reduce((statusCounts, resp) => {
                            const status = resp.status;
                            statusCounts[status] = (statusCounts[status] || 0) + 1;
                            return statusCounts;
                        }, {});

                        const responseStatsWTotal = {...responseStats, total: userResponses.length};
                        const combined: CombinedModel = {...userAndWriterModel, responseStats: responseStatsWTotal, userType: 'User'}
                        mergedPayload.push(combined);
                    }
                    queryClient
                        .invalidateQueries([key])
                        .then(() => {
                            resolve(response.payload);
                        });
                    resolve(mergedPayload);
                }
            });
        },

        retry: 0,
        retryOnMount: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,

        onError: (err) => {
            console.error(err);
        },
    });    
}
// Get's a WriterModel using either the authenticated JWT or provided identifier
export function useWriterQuery(params?: {
    identifier?: string;
    enabled?: boolean;
}) {
    const key = `writer-${params?.identifier ?? 'authenticated'}`;
    return useQuery({
        queryKey: [key],
        queryFn: async () => {
            return new Promise<WriterModel>(async (resolve, reject) => {
                const response = await genericHandler(
                    'GET',
                    'include',
                    params?.identifier
                        ? `/artemis/writer/${params.identifier}`
                        : '/artemis/writer'
                );

                if (!response || response.error) {
                    reject(response?.payload ?? 'unable to fulfill request');
                } else {
                    const user = WriterModel.fromJSON(response.payload);
                    resolve(user);
                }
            });
        },

        retry: 0,
        retryOnMount: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
        enabled: params?.enabled ?? true,
        onError: (err) => {
            console.error(err);
        },
    });
}

// Create a writer mutation
export function useCreateWriterMutation() {
    const queryClient = useQueryClient();
    return useMutation({
        mutationKey: ['create-writer'],
        mutationFn: (req: CreateWriterRequest) => {
            return new Promise(async (resolve, reject) => {
                const response = await genericHandler(
                    'POST',
                    'include',
                    '/artemis/writer',
                    CreateWriterRequest.toJSON(req) as Object
                );

                if (!response || response.error) {
                    reject(response?.payload ?? 'unable to fulfill request');
                } else {
                    queryClient
                        .invalidateQueries(['writer-authenticated'])
                        .then(() => {
                            resolve(response.payload);
                        });
                }
            });
        },

        retry: 0,
        onError: (err) => {
            console.error(err);
        },
    });
}

// Edit the user by passing any UserModel owned attrs (NOTE: MUST BE FORMATTED LIKE STORED IN DB - like capitalization)
export function useEditUserMutation() {
    const queryClient = useQueryClient();
    return useMutation({
        mutationKey: ['edit-user'],
        mutationFn: (req: Object) => {
            return new Promise(async (resolve, reject) => {
                const response = await genericHandler(
                    'POST',
                    'include',
                    '/artemis/user/edit',
                    req
                );

                if (!response || response.error) {
                    reject(response?.payload ?? 'unable to fulfill request');
                } else {
                    queryClient.invalidateQueries(['user-authenticated']);
                    resolve(response.payload);
                }
            });
        },
        retry: 0,
        onError: (err) => {
            console.error(err);
        },
    });
}

// Edit the writer by passing any WriterModel owned attrs (NOTE: MUST BE FORMATTED LIKE STORED IN DB - like capitalization)
export function useEditWriterMutation() {
    const queryClient = useQueryClient();
    return useMutation({
        mutationKey: ['edit-writer'],
        mutationFn: (req: Object) => {
            return new Promise(async (resolve, reject) => {
                const response = await genericHandler(
                    'POST',
                    'include',
                    '/artemis/writer/edit',
                    req
                );

                if (!response || response.error) {
                    reject(response?.payload ?? 'unable to fulfill request');
                } else {
                    queryClient.invalidateQueries([`writer-authenticated`]);
                    resolve(response.payload);
                }
            });
        },

        retry: 0,
        onError: (err) => {
            console.error(err);
        },
    });
}

// Search an Artemis table on any path using prefix
export function useSearchArtemisWithPrefixMutation() {
    return useMutation({
        mutationKey: ['artemis-prefix-query'],
        mutationFn: (req: Object): Promise<any> => {
            return new Promise(async (resolve, reject) => {
                const response = await genericHandler(
                    'POST',
                    'include',
                    '/artemis/prefix-query',
                    req
                );

                if (!response || response.error) {
                    reject(response?.payload ?? 'unable to fulfill request');
                } else {
                    resolve(response.payload);
                }
            });
        },

        retry: 0,
        onError: (err) => {
            console.error(err);
        },
    });
}
