/* eslint-disable */
import _m0 from "protobufjs/minimal";

export const protobufPackage = "kratos";

export interface KratosEnterpriseSignupRequest {
  email: string;
  name: string;
  password: string;
  euuid: string;
  enterpriseName: string;
}

export interface KratosLoginRequest {
  /** email or username */
  identifier: string;
  password: string;
}

export interface KratosVerifyUserRequest {
  secret: string;
  identifier: string;
}

export interface KratosSignupRequest {
  email: string;
  password: string;
}

export interface KratosResetPasswordRequest {
  /** uuid */
  identifier: string;
  secret: string;
  newPassword: string;
}

export interface KratosForgotPasswordRequest {
  /** username or email */
  identifier: string;
}

export interface KratosLoginResponse {
}

export interface KratosContinueWithProvider {
  google?: KratosProviderGoogle | undefined;
}

export interface KratosProviderGoogle {
  code: string;
  scope: string;
}

function createBaseKratosEnterpriseSignupRequest(): KratosEnterpriseSignupRequest {
  return { email: "", name: "", password: "", euuid: "", enterpriseName: "" };
}

export const KratosEnterpriseSignupRequest = {
  encode(message: KratosEnterpriseSignupRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.email !== "") {
      writer.uint32(10).string(message.email);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.password !== "") {
      writer.uint32(26).string(message.password);
    }
    if (message.euuid !== "") {
      writer.uint32(34).string(message.euuid);
    }
    if (message.enterpriseName !== "") {
      writer.uint32(42).string(message.enterpriseName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): KratosEnterpriseSignupRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseKratosEnterpriseSignupRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.email = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.password = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.euuid = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.enterpriseName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): KratosEnterpriseSignupRequest {
    return {
      email: isSet(object.email) ? String(object.email) : "",
      name: isSet(object.name) ? String(object.name) : "",
      password: isSet(object.password) ? String(object.password) : "",
      euuid: isSet(object.euuid) ? String(object.euuid) : "",
      enterpriseName: isSet(object.enterprise_name) ? String(object.enterprise_name) : "",
    };
  },

  toJSON(message: KratosEnterpriseSignupRequest): unknown {
    const obj: any = {};
    message.email !== undefined && (obj.email = message.email);
    message.name !== undefined && (obj.name = message.name);
    message.password !== undefined && (obj.password = message.password);
    message.euuid !== undefined && (obj.euuid = message.euuid);
    message.enterpriseName !== undefined && (obj.enterprise_name = message.enterpriseName);
    return obj;
  },

  create<I extends Exact<DeepPartial<KratosEnterpriseSignupRequest>, I>>(base?: I): KratosEnterpriseSignupRequest {
    return KratosEnterpriseSignupRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<KratosEnterpriseSignupRequest>, I>>(
    object: I,
  ): KratosEnterpriseSignupRequest {
    const message = createBaseKratosEnterpriseSignupRequest();
    message.email = object.email ?? "";
    message.name = object.name ?? "";
    message.password = object.password ?? "";
    message.euuid = object.euuid ?? "";
    message.enterpriseName = object.enterpriseName ?? "";
    return message;
  },
};

function createBaseKratosLoginRequest(): KratosLoginRequest {
  return { identifier: "", password: "" };
}

export const KratosLoginRequest = {
  encode(message: KratosLoginRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.identifier !== "") {
      writer.uint32(10).string(message.identifier);
    }
    if (message.password !== "") {
      writer.uint32(18).string(message.password);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): KratosLoginRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseKratosLoginRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.identifier = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.password = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): KratosLoginRequest {
    return {
      identifier: isSet(object.identifier) ? String(object.identifier) : "",
      password: isSet(object.password) ? String(object.password) : "",
    };
  },

  toJSON(message: KratosLoginRequest): unknown {
    const obj: any = {};
    message.identifier !== undefined && (obj.identifier = message.identifier);
    message.password !== undefined && (obj.password = message.password);
    return obj;
  },

  create<I extends Exact<DeepPartial<KratosLoginRequest>, I>>(base?: I): KratosLoginRequest {
    return KratosLoginRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<KratosLoginRequest>, I>>(object: I): KratosLoginRequest {
    const message = createBaseKratosLoginRequest();
    message.identifier = object.identifier ?? "";
    message.password = object.password ?? "";
    return message;
  },
};

function createBaseKratosVerifyUserRequest(): KratosVerifyUserRequest {
  return { secret: "", identifier: "" };
}

export const KratosVerifyUserRequest = {
  encode(message: KratosVerifyUserRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.secret !== "") {
      writer.uint32(10).string(message.secret);
    }
    if (message.identifier !== "") {
      writer.uint32(18).string(message.identifier);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): KratosVerifyUserRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseKratosVerifyUserRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.secret = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.identifier = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): KratosVerifyUserRequest {
    return {
      secret: isSet(object.secret) ? String(object.secret) : "",
      identifier: isSet(object.identifier) ? String(object.identifier) : "",
    };
  },

  toJSON(message: KratosVerifyUserRequest): unknown {
    const obj: any = {};
    message.secret !== undefined && (obj.secret = message.secret);
    message.identifier !== undefined && (obj.identifier = message.identifier);
    return obj;
  },

  create<I extends Exact<DeepPartial<KratosVerifyUserRequest>, I>>(base?: I): KratosVerifyUserRequest {
    return KratosVerifyUserRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<KratosVerifyUserRequest>, I>>(object: I): KratosVerifyUserRequest {
    const message = createBaseKratosVerifyUserRequest();
    message.secret = object.secret ?? "";
    message.identifier = object.identifier ?? "";
    return message;
  },
};

function createBaseKratosSignupRequest(): KratosSignupRequest {
  return { email: "", password: "" };
}

export const KratosSignupRequest = {
  encode(message: KratosSignupRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.email !== "") {
      writer.uint32(10).string(message.email);
    }
    if (message.password !== "") {
      writer.uint32(18).string(message.password);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): KratosSignupRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseKratosSignupRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.email = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.password = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): KratosSignupRequest {
    return {
      email: isSet(object.email) ? String(object.email) : "",
      password: isSet(object.password) ? String(object.password) : "",
    };
  },

  toJSON(message: KratosSignupRequest): unknown {
    const obj: any = {};
    message.email !== undefined && (obj.email = message.email);
    message.password !== undefined && (obj.password = message.password);
    return obj;
  },

  create<I extends Exact<DeepPartial<KratosSignupRequest>, I>>(base?: I): KratosSignupRequest {
    return KratosSignupRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<KratosSignupRequest>, I>>(object: I): KratosSignupRequest {
    const message = createBaseKratosSignupRequest();
    message.email = object.email ?? "";
    message.password = object.password ?? "";
    return message;
  },
};

function createBaseKratosResetPasswordRequest(): KratosResetPasswordRequest {
  return { identifier: "", secret: "", newPassword: "" };
}

export const KratosResetPasswordRequest = {
  encode(message: KratosResetPasswordRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.identifier !== "") {
      writer.uint32(10).string(message.identifier);
    }
    if (message.secret !== "") {
      writer.uint32(18).string(message.secret);
    }
    if (message.newPassword !== "") {
      writer.uint32(26).string(message.newPassword);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): KratosResetPasswordRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseKratosResetPasswordRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.identifier = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.secret = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.newPassword = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): KratosResetPasswordRequest {
    return {
      identifier: isSet(object.identifier) ? String(object.identifier) : "",
      secret: isSet(object.secret) ? String(object.secret) : "",
      newPassword: isSet(object.new_password) ? String(object.new_password) : "",
    };
  },

  toJSON(message: KratosResetPasswordRequest): unknown {
    const obj: any = {};
    message.identifier !== undefined && (obj.identifier = message.identifier);
    message.secret !== undefined && (obj.secret = message.secret);
    message.newPassword !== undefined && (obj.new_password = message.newPassword);
    return obj;
  },

  create<I extends Exact<DeepPartial<KratosResetPasswordRequest>, I>>(base?: I): KratosResetPasswordRequest {
    return KratosResetPasswordRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<KratosResetPasswordRequest>, I>>(object: I): KratosResetPasswordRequest {
    const message = createBaseKratosResetPasswordRequest();
    message.identifier = object.identifier ?? "";
    message.secret = object.secret ?? "";
    message.newPassword = object.newPassword ?? "";
    return message;
  },
};

function createBaseKratosForgotPasswordRequest(): KratosForgotPasswordRequest {
  return { identifier: "" };
}

export const KratosForgotPasswordRequest = {
  encode(message: KratosForgotPasswordRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.identifier !== "") {
      writer.uint32(10).string(message.identifier);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): KratosForgotPasswordRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseKratosForgotPasswordRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.identifier = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): KratosForgotPasswordRequest {
    return { identifier: isSet(object.identifier) ? String(object.identifier) : "" };
  },

  toJSON(message: KratosForgotPasswordRequest): unknown {
    const obj: any = {};
    message.identifier !== undefined && (obj.identifier = message.identifier);
    return obj;
  },

  create<I extends Exact<DeepPartial<KratosForgotPasswordRequest>, I>>(base?: I): KratosForgotPasswordRequest {
    return KratosForgotPasswordRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<KratosForgotPasswordRequest>, I>>(object: I): KratosForgotPasswordRequest {
    const message = createBaseKratosForgotPasswordRequest();
    message.identifier = object.identifier ?? "";
    return message;
  },
};

function createBaseKratosLoginResponse(): KratosLoginResponse {
  return {};
}

export const KratosLoginResponse = {
  encode(_: KratosLoginResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): KratosLoginResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseKratosLoginResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): KratosLoginResponse {
    return {};
  },

  toJSON(_: KratosLoginResponse): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<KratosLoginResponse>, I>>(base?: I): KratosLoginResponse {
    return KratosLoginResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<KratosLoginResponse>, I>>(_: I): KratosLoginResponse {
    const message = createBaseKratosLoginResponse();
    return message;
  },
};

function createBaseKratosContinueWithProvider(): KratosContinueWithProvider {
  return { google: undefined };
}

export const KratosContinueWithProvider = {
  encode(message: KratosContinueWithProvider, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.google !== undefined) {
      KratosProviderGoogle.encode(message.google, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): KratosContinueWithProvider {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseKratosContinueWithProvider();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.google = KratosProviderGoogle.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): KratosContinueWithProvider {
    return { google: isSet(object.google) ? KratosProviderGoogle.fromJSON(object.google) : undefined };
  },

  toJSON(message: KratosContinueWithProvider): unknown {
    const obj: any = {};
    message.google !== undefined &&
      (obj.google = message.google ? KratosProviderGoogle.toJSON(message.google) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<KratosContinueWithProvider>, I>>(base?: I): KratosContinueWithProvider {
    return KratosContinueWithProvider.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<KratosContinueWithProvider>, I>>(object: I): KratosContinueWithProvider {
    const message = createBaseKratosContinueWithProvider();
    message.google = (object.google !== undefined && object.google !== null)
      ? KratosProviderGoogle.fromPartial(object.google)
      : undefined;
    return message;
  },
};

function createBaseKratosProviderGoogle(): KratosProviderGoogle {
  return { code: "", scope: "" };
}

export const KratosProviderGoogle = {
  encode(message: KratosProviderGoogle, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.code !== "") {
      writer.uint32(10).string(message.code);
    }
    if (message.scope !== "") {
      writer.uint32(18).string(message.scope);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): KratosProviderGoogle {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseKratosProviderGoogle();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.code = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.scope = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): KratosProviderGoogle {
    return {
      code: isSet(object.code) ? String(object.code) : "",
      scope: isSet(object.scope) ? String(object.scope) : "",
    };
  },

  toJSON(message: KratosProviderGoogle): unknown {
    const obj: any = {};
    message.code !== undefined && (obj.code = message.code);
    message.scope !== undefined && (obj.scope = message.scope);
    return obj;
  },

  create<I extends Exact<DeepPartial<KratosProviderGoogle>, I>>(base?: I): KratosProviderGoogle {
    return KratosProviderGoogle.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<KratosProviderGoogle>, I>>(object: I): KratosProviderGoogle {
    const message = createBaseKratosProviderGoogle();
    message.code = object.code ?? "";
    message.scope = object.scope ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
