import { CombinedModel, useEditEnterpriseMutation, useEditUserMutation, useUpdateEnterpriseMembershipMutation, useUserQuery } from '@cosmogony/reffy-hooks';
import {useRef, useLayoutEffect, useEffect} from 'react';
import { Boolean } from '@cosmogony/reffy-pb/common/pb/common';
import { EnterpriseModel } from '@cosmogony/reffy-pb/apollo-svc/pkg/pb/apollo';
import Loading from './Loading';

interface RemoveUserModalProps {
  userToRemove: CombinedModel | null;
  setUserToRemove: (userToRemove: CombinedModel | null) => void;
  enterprise: EnterpriseModel;
}

const NIL_UUID = '00000000-0000-0000-0000-000000000000';

export default function RemoveUserModal({userToRemove, setUserToRemove, enterprise}: RemoveUserModalProps) {
  const dialog = useRef<HTMLDialogElement>(null);
  const enterpriseMutation = useEditEnterpriseMutation();
  const enterpriseMemberMutation = useUpdateEnterpriseMembershipMutation();
  const editUserMutation = useEditUserMutation();
  const userQuery = useUserQuery();


  useEffect(() => {
    document.addEventListener('keydown', (e) => {
      if (e.code === 'Escape') {
        setUserToRemove(null);
      }
    });

    document.addEventListener('click', (e) => {
      const node = e.target as HTMLDialogElement;
      if (node.nodeName === 'DIALOG') {
        setUserToRemove(null);
      }
    });
  }, [])

  useLayoutEffect(() => {
    if (userToRemove) {
      dialog.current?.showModal();
    } else {
      dialog.current?.close();
    }
  }, [userToRemove]);

  if (enterpriseMutation.isLoading || enterpriseMemberMutation.isLoading) {
    return <Loading />;
  }

  if (userToRemove == null) {
    return <></>;
  }

  const removeUser = async () => {
    // if owner or admin, remove from enterprise model (enterprise mutation)
    try {
      if (userToRemove.userType === 'Admin') {
        enterprise.admins = enterprise.admins.filter(admin => admin !== userToRemove.uuid);
        await enterpriseMutation.mutateAsync({
            euuid: enterprise.euuid, 
            name: enterprise.name, 
            owner: enterprise.owner, 
            numSeats: enterprise.numSeats, 
            numFilledSeats: enterprise.numFilledSeats, 
            subscriptionStart: enterprise.subscriptionStart, 
            subscriptionEnd: enterprise.subscriptionEnd, 
            admins: enterprise.admins
        });
      }
      // enterprise member mutation to decrement seats filled
      await enterpriseMemberMutation.mutateAsync({euuid: enterprise.euuid, onboarding: Boolean.BOOLEAN_FALSE});
      // edit user to remove enterprise id 
      await editUserMutation.mutateAsync({
        Uuid: userToRemove.uuid,
        EnterpriseId: NIL_UUID
      });
      if (userQuery.isSuccess) {
        setUserToRemove(null);
        window.location.replace(window.location.href);
      }
    } catch (err) {
      console.error("Error with mutations in removeusermodal", err);
    }
  };


  return (
    <dialog ref={dialog} className="backdrop:bg-black/75 block absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 max-w-[500px] w-11/12 m-0">
      <div className='sm:p-10 p-5 flex flex-col'>
        <h1 className="text-xl font-bold m-auto text-center hyphens-auto">Are you sure you want to remove this user from {enterprise.name}'s account?</h1>
        <div className='flex flex-col items-center'>
          <img width="200px" src={userToRemove.pfp?.url} className='rounded-full mt-2 mb-1' />
          <p>{userToRemove.name}</p>
          <p className='text-center'>{userToRemove.role}</p>
          <p>{userToRemove.email}</p>
        </div>
        <div className='flex flex-row m-auto gap-10 mt-5'>
          <button autoFocus onClick={() => setUserToRemove(null)} className="px-3 py-2 bg-[#ccc] w-32 font-bold text-reffyblack">Cancel</button>
          <button onClick={() => removeUser()} className="px-3 py-2 bg-reffygold font-bold w-32 text-reffyblack">Remove User</button>
        </div>
        <p className='mt-4 text-sm text-justify hyphens-auto'>Removing {userToRemove.name} will exclude them from your organization’s RefBuilder Pro subscription, but it won’t delete their account. {userToRemove.name} will still be able to access their account information, but will not have access to premium features unless they re-subscribe to RefBuilder Pro on an individual basis.</p>
      </div>
    </dialog>
  );
}

