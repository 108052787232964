/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";
import { Boolean, booleanFromJSON, booleanToJSON, DocumentRecord } from "../../../common/pb/common";

export const protobufPackage = "litae";

export enum RequestStatus {
  REQUEST_UNSPECIFIED = 0,
  REQUEST_OPEN = 1,
  REQUEST_COMPLETED = 2,
  REQUEST_DECLINED = 3,
  UNRECOGNIZED = -1,
}

export function requestStatusFromJSON(object: any): RequestStatus {
  switch (object) {
    case 0:
    case "REQUEST_UNSPECIFIED":
      return RequestStatus.REQUEST_UNSPECIFIED;
    case 1:
    case "REQUEST_OPEN":
      return RequestStatus.REQUEST_OPEN;
    case 2:
    case "REQUEST_COMPLETED":
      return RequestStatus.REQUEST_COMPLETED;
    case 3:
    case "REQUEST_DECLINED":
      return RequestStatus.REQUEST_DECLINED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return RequestStatus.UNRECOGNIZED;
  }
}

export function requestStatusToJSON(object: RequestStatus): number {
  switch (object) {
    case RequestStatus.REQUEST_UNSPECIFIED:
      return 0;
    case RequestStatus.REQUEST_OPEN:
      return 1;
    case RequestStatus.REQUEST_COMPLETED:
      return 2;
    case RequestStatus.REQUEST_DECLINED:
      return 3;
    case RequestStatus.UNRECOGNIZED:
    default:
      return -1;
  }
}

export interface EmptyResponse {
}

export interface ResponseModel {
  ruuid: string;
  ouuid: string;
  responses: string[];
  documents: DocumentRecord[];
  status: RequestStatus;
  dateDue: number;
  luuid: string;
  isWriteNow: Boolean;
  updatedAt: number;
}

export interface CreateResponseRequest {
  ouuid: string;
  responses: string[];
  documents: DocumentRecord[];
  dateDue: number;
  isWriteNow: Boolean;
}

export interface EditResponseRequest {
  ruuid: string;
  status: RequestStatus;
  luuid: string;
  writerMessage: string;
}

export interface GetResponseRequest {
  ruuid: string;
}

export interface GetWritersResponsesRequest {
  /** maps to ouuid in ResponseModel */
  uuid: string;
}

export interface GetWritersResponsesResponse {
  responses: ResponseModel[];
}

function createBaseEmptyResponse(): EmptyResponse {
  return {};
}

export const EmptyResponse = {
  encode(_: EmptyResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EmptyResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEmptyResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): EmptyResponse {
    return {};
  },

  toJSON(_: EmptyResponse): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<EmptyResponse>, I>>(base?: I): EmptyResponse {
    return EmptyResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<EmptyResponse>, I>>(_: I): EmptyResponse {
    const message = createBaseEmptyResponse();
    return message;
  },
};

function createBaseResponseModel(): ResponseModel {
  return {
    ruuid: "",
    ouuid: "",
    responses: [],
    documents: [],
    status: 0,
    dateDue: 0,
    luuid: "",
    isWriteNow: 0,
    updatedAt: 0,
  };
}

export const ResponseModel = {
  encode(message: ResponseModel, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.ruuid !== "") {
      writer.uint32(10).string(message.ruuid);
    }
    if (message.ouuid !== "") {
      writer.uint32(18).string(message.ouuid);
    }
    for (const v of message.responses) {
      writer.uint32(26).string(v!);
    }
    for (const v of message.documents) {
      DocumentRecord.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    if (message.status !== 0) {
      writer.uint32(40).int32(message.status);
    }
    if (message.dateDue !== 0) {
      writer.uint32(48).int64(message.dateDue);
    }
    if (message.luuid !== "") {
      writer.uint32(58).string(message.luuid);
    }
    if (message.isWriteNow !== 0) {
      writer.uint32(64).int32(message.isWriteNow);
    }
    if (message.updatedAt !== 0) {
      writer.uint32(72).int64(message.updatedAt);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ResponseModel {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseResponseModel();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.ruuid = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.ouuid = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.responses.push(reader.string());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.documents.push(DocumentRecord.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.dateDue = longToNumber(reader.int64() as Long);
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.luuid = reader.string();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.isWriteNow = reader.int32() as any;
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.updatedAt = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ResponseModel {
    return {
      ruuid: isSet(object.ruuid) ? String(object.ruuid) : "",
      ouuid: isSet(object.ouuid) ? String(object.ouuid) : "",
      responses: Array.isArray(object?.responses) ? object.responses.map((e: any) => String(e)) : [],
      documents: Array.isArray(object?.documents) ? object.documents.map((e: any) => DocumentRecord.fromJSON(e)) : [],
      status: isSet(object.status) ? requestStatusFromJSON(object.status) : 0,
      dateDue: isSet(object.date_due) ? Number(object.date_due) : 0,
      luuid: isSet(object.luuid) ? String(object.luuid) : "",
      isWriteNow: isSet(object.is_write_now) ? booleanFromJSON(object.is_write_now) : 0,
      updatedAt: isSet(object.updated_at) ? Number(object.updated_at) : 0,
    };
  },

  toJSON(message: ResponseModel): unknown {
    const obj: any = {};
    message.ruuid !== undefined && (obj.ruuid = message.ruuid);
    message.ouuid !== undefined && (obj.ouuid = message.ouuid);
    if (message.responses) {
      obj.responses = message.responses.map((e) => e);
    } else {
      obj.responses = [];
    }
    if (message.documents) {
      obj.documents = message.documents.map((e) => e ? DocumentRecord.toJSON(e) : undefined);
    } else {
      obj.documents = [];
    }
    message.status !== undefined && (obj.status = requestStatusToJSON(message.status));
    message.dateDue !== undefined && (obj.date_due = Math.round(message.dateDue));
    message.luuid !== undefined && (obj.luuid = message.luuid);
    message.isWriteNow !== undefined && (obj.is_write_now = booleanToJSON(message.isWriteNow));
    message.updatedAt !== undefined && (obj.updated_at = Math.round(message.updatedAt));
    return obj;
  },

  create<I extends Exact<DeepPartial<ResponseModel>, I>>(base?: I): ResponseModel {
    return ResponseModel.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ResponseModel>, I>>(object: I): ResponseModel {
    const message = createBaseResponseModel();
    message.ruuid = object.ruuid ?? "";
    message.ouuid = object.ouuid ?? "";
    message.responses = object.responses?.map((e) => e) || [];
    message.documents = object.documents?.map((e) => DocumentRecord.fromPartial(e)) || [];
    message.status = object.status ?? 0;
    message.dateDue = object.dateDue ?? 0;
    message.luuid = object.luuid ?? "";
    message.isWriteNow = object.isWriteNow ?? 0;
    message.updatedAt = object.updatedAt ?? 0;
    return message;
  },
};

function createBaseCreateResponseRequest(): CreateResponseRequest {
  return { ouuid: "", responses: [], documents: [], dateDue: 0, isWriteNow: 0 };
}

export const CreateResponseRequest = {
  encode(message: CreateResponseRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.ouuid !== "") {
      writer.uint32(10).string(message.ouuid);
    }
    for (const v of message.responses) {
      writer.uint32(18).string(v!);
    }
    for (const v of message.documents) {
      DocumentRecord.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    if (message.dateDue !== 0) {
      writer.uint32(32).int64(message.dateDue);
    }
    if (message.isWriteNow !== 0) {
      writer.uint32(40).int32(message.isWriteNow);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateResponseRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateResponseRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.ouuid = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.responses.push(reader.string());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.documents.push(DocumentRecord.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.dateDue = longToNumber(reader.int64() as Long);
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.isWriteNow = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateResponseRequest {
    return {
      ouuid: isSet(object.ouuid) ? String(object.ouuid) : "",
      responses: Array.isArray(object?.responses) ? object.responses.map((e: any) => String(e)) : [],
      documents: Array.isArray(object?.documents) ? object.documents.map((e: any) => DocumentRecord.fromJSON(e)) : [],
      dateDue: isSet(object.date_due) ? Number(object.date_due) : 0,
      isWriteNow: isSet(object.is_write_now) ? booleanFromJSON(object.is_write_now) : 0,
    };
  },

  toJSON(message: CreateResponseRequest): unknown {
    const obj: any = {};
    message.ouuid !== undefined && (obj.ouuid = message.ouuid);
    if (message.responses) {
      obj.responses = message.responses.map((e) => e);
    } else {
      obj.responses = [];
    }
    if (message.documents) {
      obj.documents = message.documents.map((e) => e ? DocumentRecord.toJSON(e) : undefined);
    } else {
      obj.documents = [];
    }
    message.dateDue !== undefined && (obj.date_due = Math.round(message.dateDue));
    message.isWriteNow !== undefined && (obj.is_write_now = booleanToJSON(message.isWriteNow));
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateResponseRequest>, I>>(base?: I): CreateResponseRequest {
    return CreateResponseRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<CreateResponseRequest>, I>>(object: I): CreateResponseRequest {
    const message = createBaseCreateResponseRequest();
    message.ouuid = object.ouuid ?? "";
    message.responses = object.responses?.map((e) => e) || [];
    message.documents = object.documents?.map((e) => DocumentRecord.fromPartial(e)) || [];
    message.dateDue = object.dateDue ?? 0;
    message.isWriteNow = object.isWriteNow ?? 0;
    return message;
  },
};

function createBaseEditResponseRequest(): EditResponseRequest {
  return { ruuid: "", status: 0, luuid: "", writerMessage: "" };
}

export const EditResponseRequest = {
  encode(message: EditResponseRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.ruuid !== "") {
      writer.uint32(10).string(message.ruuid);
    }
    if (message.status !== 0) {
      writer.uint32(16).int32(message.status);
    }
    if (message.luuid !== "") {
      writer.uint32(26).string(message.luuid);
    }
    if (message.writerMessage !== "") {
      writer.uint32(34).string(message.writerMessage);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EditResponseRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEditResponseRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.ruuid = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.luuid = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.writerMessage = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EditResponseRequest {
    return {
      ruuid: isSet(object.ruuid) ? String(object.ruuid) : "",
      status: isSet(object.status) ? requestStatusFromJSON(object.status) : 0,
      luuid: isSet(object.luuid) ? String(object.luuid) : "",
      writerMessage: isSet(object.writer_message) ? String(object.writer_message) : "",
    };
  },

  toJSON(message: EditResponseRequest): unknown {
    const obj: any = {};
    message.ruuid !== undefined && (obj.ruuid = message.ruuid);
    message.status !== undefined && (obj.status = requestStatusToJSON(message.status));
    message.luuid !== undefined && (obj.luuid = message.luuid);
    message.writerMessage !== undefined && (obj.writer_message = message.writerMessage);
    return obj;
  },

  create<I extends Exact<DeepPartial<EditResponseRequest>, I>>(base?: I): EditResponseRequest {
    return EditResponseRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<EditResponseRequest>, I>>(object: I): EditResponseRequest {
    const message = createBaseEditResponseRequest();
    message.ruuid = object.ruuid ?? "";
    message.status = object.status ?? 0;
    message.luuid = object.luuid ?? "";
    message.writerMessage = object.writerMessage ?? "";
    return message;
  },
};

function createBaseGetResponseRequest(): GetResponseRequest {
  return { ruuid: "" };
}

export const GetResponseRequest = {
  encode(message: GetResponseRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.ruuid !== "") {
      writer.uint32(10).string(message.ruuid);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetResponseRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetResponseRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.ruuid = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetResponseRequest {
    return { ruuid: isSet(object.ruuid) ? String(object.ruuid) : "" };
  },

  toJSON(message: GetResponseRequest): unknown {
    const obj: any = {};
    message.ruuid !== undefined && (obj.ruuid = message.ruuid);
    return obj;
  },

  create<I extends Exact<DeepPartial<GetResponseRequest>, I>>(base?: I): GetResponseRequest {
    return GetResponseRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GetResponseRequest>, I>>(object: I): GetResponseRequest {
    const message = createBaseGetResponseRequest();
    message.ruuid = object.ruuid ?? "";
    return message;
  },
};

function createBaseGetWritersResponsesRequest(): GetWritersResponsesRequest {
  return { uuid: "" };
}

export const GetWritersResponsesRequest = {
  encode(message: GetWritersResponsesRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.uuid !== "") {
      writer.uint32(10).string(message.uuid);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetWritersResponsesRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetWritersResponsesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.uuid = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetWritersResponsesRequest {
    return { uuid: isSet(object.uuid) ? String(object.uuid) : "" };
  },

  toJSON(message: GetWritersResponsesRequest): unknown {
    const obj: any = {};
    message.uuid !== undefined && (obj.uuid = message.uuid);
    return obj;
  },

  create<I extends Exact<DeepPartial<GetWritersResponsesRequest>, I>>(base?: I): GetWritersResponsesRequest {
    return GetWritersResponsesRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GetWritersResponsesRequest>, I>>(object: I): GetWritersResponsesRequest {
    const message = createBaseGetWritersResponsesRequest();
    message.uuid = object.uuid ?? "";
    return message;
  },
};

function createBaseGetWritersResponsesResponse(): GetWritersResponsesResponse {
  return { responses: [] };
}

export const GetWritersResponsesResponse = {
  encode(message: GetWritersResponsesResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.responses) {
      ResponseModel.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetWritersResponsesResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetWritersResponsesResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.responses.push(ResponseModel.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetWritersResponsesResponse {
    return {
      responses: Array.isArray(object?.responses) ? object.responses.map((e: any) => ResponseModel.fromJSON(e)) : [],
    };
  },

  toJSON(message: GetWritersResponsesResponse): unknown {
    const obj: any = {};
    if (message.responses) {
      obj.responses = message.responses.map((e) => e ? ResponseModel.toJSON(e) : undefined);
    } else {
      obj.responses = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetWritersResponsesResponse>, I>>(base?: I): GetWritersResponsesResponse {
    return GetWritersResponsesResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GetWritersResponsesResponse>, I>>(object: I): GetWritersResponsesResponse {
    const message = createBaseGetWritersResponsesResponse();
    message.responses = object.responses?.map((e) => ResponseModel.fromPartial(e)) || [];
    return message;
  },
};

export interface LitaeService {
  CreateResponse(request: CreateResponseRequest): Promise<ResponseModel>;
  EditResponse(request: EditResponseRequest): Promise<EmptyResponse>;
  DeleteResponse(request: GetResponseRequest): Promise<EmptyResponse>;
  GetResponse(request: GetResponseRequest): Promise<ResponseModel>;
  GetWritersResponses(request: GetWritersResponsesRequest): Promise<GetWritersResponsesResponse>;
}

export class LitaeServiceClientImpl implements LitaeService {
  private readonly rpc: Rpc;
  private readonly service: string;
  constructor(rpc: Rpc, opts?: { service?: string }) {
    this.service = opts?.service || "litae.LitaeService";
    this.rpc = rpc;
    this.CreateResponse = this.CreateResponse.bind(this);
    this.EditResponse = this.EditResponse.bind(this);
    this.DeleteResponse = this.DeleteResponse.bind(this);
    this.GetResponse = this.GetResponse.bind(this);
    this.GetWritersResponses = this.GetWritersResponses.bind(this);
  }
  CreateResponse(request: CreateResponseRequest): Promise<ResponseModel> {
    const data = CreateResponseRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "CreateResponse", data);
    return promise.then((data) => ResponseModel.decode(_m0.Reader.create(data)));
  }

  EditResponse(request: EditResponseRequest): Promise<EmptyResponse> {
    const data = EditResponseRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "EditResponse", data);
    return promise.then((data) => EmptyResponse.decode(_m0.Reader.create(data)));
  }

  DeleteResponse(request: GetResponseRequest): Promise<EmptyResponse> {
    const data = GetResponseRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "DeleteResponse", data);
    return promise.then((data) => EmptyResponse.decode(_m0.Reader.create(data)));
  }

  GetResponse(request: GetResponseRequest): Promise<ResponseModel> {
    const data = GetResponseRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "GetResponse", data);
    return promise.then((data) => ResponseModel.decode(_m0.Reader.create(data)));
  }

  GetWritersResponses(request: GetWritersResponsesRequest): Promise<GetWritersResponsesResponse> {
    const data = GetWritersResponsesRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "GetWritersResponses", data);
    return promise.then((data) => GetWritersResponsesResponse.decode(_m0.Reader.create(data)));
  }
}

interface Rpc {
  request(service: string, method: string, data: Uint8Array): Promise<Uint8Array>;
}

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var tsProtoGlobalThis: any = (() => {
  if (typeof globalThis !== "undefined") {
    return globalThis;
  }
  if (typeof self !== "undefined") {
    return self;
  }
  if (typeof window !== "undefined") {
    return window;
  }
  if (typeof global !== "undefined") {
    return global;
  }
  throw "Unable to locate global object";
})();

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new tsProtoGlobalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
