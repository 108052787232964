import { useNavigate } from 'react-router-dom';

export default function PrivacyFooter(prop: {className?: string}) {
    return (
        <div className={`${prop.className} absolute bottom-0 w-full bg-reffyblack py-2 font-['GT Pressura Standard'] flex flex-row sm:justify-between truncate`}>
    
          <div className="md:ml-16 flex hidden sm:block">
            <a href="https://refbuilder.com/privacy" className="ml-4">
              <span className="text-[#ccc]">PRIVACY POLICY</span>
            </a>
            <a href="https://refbuilder.com/terms-of-service" className="ml-4">
              <span className="text-[#ccc]">TERMS OF SERVICE</span>
            </a>
          </div>
    
          <div className="mr-16 flex">
            <p className="ml-4">
              <span className="text-[#ccc]">COPYRIGHT 2024 REFFY INC.</span>
            </p>
            <p className="ml-4">
              <span className="text-[#ccc]">ALL RIGHTS RESERVED</span>
            </p>
          </div>
        </div>
    );
  }
  
