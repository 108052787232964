/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";
import { Boolean, booleanFromJSON, booleanToJSON, DocumentRecord, Permissions } from "../../../common/pb/common";

export const protobufPackage = "artemis";

export enum UserStatus {
  STATUS_UNDEFINED = 0,
  /** STATUS_BLOCKED - Defines a user who is blocked from the platform */
  STATUS_BLOCKED = 1,
  /** STATUS_UNVERIFIED - Defines a user who's email is unverified */
  STATUS_UNVERIFIED = 2,
  /** STATUS_INCOMPLETE - Defines a uesr who has been verified but account has not been setup */
  STATUS_INCOMPLETE = 3,
  /** STATUS_ACTIVE - Defines a user who's email has been verified */
  STATUS_ACTIVE = 4,
  UNRECOGNIZED = -1,
}

export function userStatusFromJSON(object: any): UserStatus {
  switch (object) {
    case 0:
    case "STATUS_UNDEFINED":
      return UserStatus.STATUS_UNDEFINED;
    case 1:
    case "STATUS_BLOCKED":
      return UserStatus.STATUS_BLOCKED;
    case 2:
    case "STATUS_UNVERIFIED":
      return UserStatus.STATUS_UNVERIFIED;
    case 3:
    case "STATUS_INCOMPLETE":
      return UserStatus.STATUS_INCOMPLETE;
    case 4:
    case "STATUS_ACTIVE":
      return UserStatus.STATUS_ACTIVE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return UserStatus.UNRECOGNIZED;
  }
}

export function userStatusToJSON(object: UserStatus): number {
  switch (object) {
    case UserStatus.STATUS_UNDEFINED:
      return 0;
    case UserStatus.STATUS_BLOCKED:
      return 1;
    case UserStatus.STATUS_UNVERIFIED:
      return 2;
    case UserStatus.STATUS_INCOMPLETE:
      return 3;
    case UserStatus.STATUS_ACTIVE:
      return 4;
    case UserStatus.UNRECOGNIZED:
    default:
      return -1;
  }
}

export interface EmptyResponse {
}

export interface UserModel {
  uuid: string;
  createdAt: number;
  updatedAt: number;
  email: string;
  name: string;
  passwordHash: string;
  verificationSecret: string;
  pfp: DocumentRecord | undefined;
  permissions: Permissions | undefined;
  username: string;
  status: UserStatus;
  notificationsDisabled: Boolean;
  enterpriseId: string;
  lastLoginAt: number;
}

export interface WriterModel {
  uuid: string;
  field: string;
  role: string;
  status: UserStatus;
  preferredGreeting: string;
  organization: string;
  stayInformed: Boolean;
  additionalQuestions: string[];
  additionalDocuments: string[];
  letterHead: DocumentRecord | undefined;
  createdAt: number;
  updatedAt: number;
}

export interface ApplicantModel {
  uuid: string;
  credits: number;
  paidCredits: number;
  creditsRefreshDate: number;
  createdAt: number;
  updatedAt: number;
}

export interface CreateUserRequest {
  email: string;
  name: string;
  passwordHash: string;
}

/** Get any kind of user */
export interface GetUserRequest {
  /** Can be either username or uuid */
  identifier: string;
}

export interface CreateWriterRequest {
  uuid: string;
  field: string;
  role: string;
  preferredGreeting: string;
  organization: string;
  stayInformed: Boolean;
}

export interface EditWriterRequest {
  uuid: string;
  writer: WriterModel | undefined;
}

export interface EditUserRequest {
  uuid: string;
  user: UserModel | undefined;
}

export interface CreateApplicantRequest {
  uuid: string;
}

export interface CreateApplicantResponse {
  applicant: ApplicantModel | undefined;
}

export interface GetApplicantRequest {
  uuid: string;
}

export interface GetApplicantResponse {
  applicant: ApplicantModel | undefined;
}

export interface EditApplicantRequest {
  applicant: ApplicantModel | undefined;
}

export interface EditApplicantResponse {
}

export interface PrefixQueryRequest {
  table: string;
  path: string;
  prefix: string;
}

export interface PrefixQueryResponse {
  users?: ListOfUsers | undefined;
  writers?: ListOfWriters | undefined;
}

export interface ListOfUsers {
  users: UserModel[];
}

export interface ListOfWriters {
  writers: WriterModel[];
}

export interface GetAllByEnterpriseIdRequest {
  enterpriseId: string;
}

/** For a given index in the list, get the user and its corresponding writer model */
export interface GetAllByEnterpriseIdResponse {
  users: UserModel[];
  writers: WriterModel[];
}

function createBaseEmptyResponse(): EmptyResponse {
  return {};
}

export const EmptyResponse = {
  encode(_: EmptyResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EmptyResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEmptyResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): EmptyResponse {
    return {};
  },

  toJSON(_: EmptyResponse): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<EmptyResponse>, I>>(base?: I): EmptyResponse {
    return EmptyResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<EmptyResponse>, I>>(_: I): EmptyResponse {
    const message = createBaseEmptyResponse();
    return message;
  },
};

function createBaseUserModel(): UserModel {
  return {
    uuid: "",
    createdAt: 0,
    updatedAt: 0,
    email: "",
    name: "",
    passwordHash: "",
    verificationSecret: "",
    pfp: undefined,
    permissions: undefined,
    username: "",
    status: 0,
    notificationsDisabled: 0,
    enterpriseId: "",
    lastLoginAt: 0,
  };
}

export const UserModel = {
  encode(message: UserModel, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.uuid !== "") {
      writer.uint32(10).string(message.uuid);
    }
    if (message.createdAt !== 0) {
      writer.uint32(16).int64(message.createdAt);
    }
    if (message.updatedAt !== 0) {
      writer.uint32(24).int64(message.updatedAt);
    }
    if (message.email !== "") {
      writer.uint32(34).string(message.email);
    }
    if (message.name !== "") {
      writer.uint32(42).string(message.name);
    }
    if (message.passwordHash !== "") {
      writer.uint32(50).string(message.passwordHash);
    }
    if (message.verificationSecret !== "") {
      writer.uint32(58).string(message.verificationSecret);
    }
    if (message.pfp !== undefined) {
      DocumentRecord.encode(message.pfp, writer.uint32(66).fork()).ldelim();
    }
    if (message.permissions !== undefined) {
      Permissions.encode(message.permissions, writer.uint32(74).fork()).ldelim();
    }
    if (message.username !== "") {
      writer.uint32(82).string(message.username);
    }
    if (message.status !== 0) {
      writer.uint32(88).int32(message.status);
    }
    if (message.notificationsDisabled !== 0) {
      writer.uint32(96).int32(message.notificationsDisabled);
    }
    if (message.enterpriseId !== "") {
      writer.uint32(106).string(message.enterpriseId);
    }
    if (message.lastLoginAt !== 0) {
      writer.uint32(112).int64(message.lastLoginAt);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserModel {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserModel();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.uuid = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.createdAt = longToNumber(reader.int64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.updatedAt = longToNumber(reader.int64() as Long);
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.email = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.name = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.passwordHash = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.verificationSecret = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.pfp = DocumentRecord.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.permissions = Permissions.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.username = reader.string();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.notificationsDisabled = reader.int32() as any;
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.enterpriseId = reader.string();
          continue;
        case 14:
          if (tag !== 112) {
            break;
          }

          message.lastLoginAt = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserModel {
    return {
      uuid: isSet(object.uuid) ? String(object.uuid) : "",
      createdAt: isSet(object.created_at) ? Number(object.created_at) : 0,
      updatedAt: isSet(object.updated_at) ? Number(object.updated_at) : 0,
      email: isSet(object.email) ? String(object.email) : "",
      name: isSet(object.name) ? String(object.name) : "",
      passwordHash: isSet(object.password_hash) ? String(object.password_hash) : "",
      verificationSecret: isSet(object.verification_secret) ? String(object.verification_secret) : "",
      pfp: isSet(object.pfp) ? DocumentRecord.fromJSON(object.pfp) : undefined,
      permissions: isSet(object.permissions) ? Permissions.fromJSON(object.permissions) : undefined,
      username: isSet(object.username) ? String(object.username) : "",
      status: isSet(object.status) ? userStatusFromJSON(object.status) : 0,
      notificationsDisabled: isSet(object.notifications_disabled) ? booleanFromJSON(object.notifications_disabled) : 0,
      enterpriseId: isSet(object.enterprise_id) ? String(object.enterprise_id) : "",
      lastLoginAt: isSet(object.last_login_at) ? Number(object.last_login_at) : 0,
    };
  },

  toJSON(message: UserModel): unknown {
    const obj: any = {};
    message.uuid !== undefined && (obj.uuid = message.uuid);
    message.createdAt !== undefined && (obj.created_at = Math.round(message.createdAt));
    message.updatedAt !== undefined && (obj.updated_at = Math.round(message.updatedAt));
    message.email !== undefined && (obj.email = message.email);
    message.name !== undefined && (obj.name = message.name);
    message.passwordHash !== undefined && (obj.password_hash = message.passwordHash);
    message.verificationSecret !== undefined && (obj.verification_secret = message.verificationSecret);
    message.pfp !== undefined && (obj.pfp = message.pfp ? DocumentRecord.toJSON(message.pfp) : undefined);
    message.permissions !== undefined &&
      (obj.permissions = message.permissions ? Permissions.toJSON(message.permissions) : undefined);
    message.username !== undefined && (obj.username = message.username);
    message.status !== undefined && (obj.status = userStatusToJSON(message.status));
    message.notificationsDisabled !== undefined &&
      (obj.notifications_disabled = booleanToJSON(message.notificationsDisabled));
    message.enterpriseId !== undefined && (obj.enterprise_id = message.enterpriseId);
    message.lastLoginAt !== undefined && (obj.last_login_at = Math.round(message.lastLoginAt));
    return obj;
  },

  create<I extends Exact<DeepPartial<UserModel>, I>>(base?: I): UserModel {
    return UserModel.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UserModel>, I>>(object: I): UserModel {
    const message = createBaseUserModel();
    message.uuid = object.uuid ?? "";
    message.createdAt = object.createdAt ?? 0;
    message.updatedAt = object.updatedAt ?? 0;
    message.email = object.email ?? "";
    message.name = object.name ?? "";
    message.passwordHash = object.passwordHash ?? "";
    message.verificationSecret = object.verificationSecret ?? "";
    message.pfp = (object.pfp !== undefined && object.pfp !== null)
      ? DocumentRecord.fromPartial(object.pfp)
      : undefined;
    message.permissions = (object.permissions !== undefined && object.permissions !== null)
      ? Permissions.fromPartial(object.permissions)
      : undefined;
    message.username = object.username ?? "";
    message.status = object.status ?? 0;
    message.notificationsDisabled = object.notificationsDisabled ?? 0;
    message.enterpriseId = object.enterpriseId ?? "";
    message.lastLoginAt = object.lastLoginAt ?? 0;
    return message;
  },
};

function createBaseWriterModel(): WriterModel {
  return {
    uuid: "",
    field: "",
    role: "",
    status: 0,
    preferredGreeting: "",
    organization: "",
    stayInformed: 0,
    additionalQuestions: [],
    additionalDocuments: [],
    letterHead: undefined,
    createdAt: 0,
    updatedAt: 0,
  };
}

export const WriterModel = {
  encode(message: WriterModel, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.uuid !== "") {
      writer.uint32(10).string(message.uuid);
    }
    if (message.field !== "") {
      writer.uint32(18).string(message.field);
    }
    if (message.role !== "") {
      writer.uint32(26).string(message.role);
    }
    if (message.status !== 0) {
      writer.uint32(32).int32(message.status);
    }
    if (message.preferredGreeting !== "") {
      writer.uint32(50).string(message.preferredGreeting);
    }
    if (message.organization !== "") {
      writer.uint32(58).string(message.organization);
    }
    if (message.stayInformed !== 0) {
      writer.uint32(64).int32(message.stayInformed);
    }
    for (const v of message.additionalQuestions) {
      writer.uint32(74).string(v!);
    }
    for (const v of message.additionalDocuments) {
      writer.uint32(82).string(v!);
    }
    if (message.letterHead !== undefined) {
      DocumentRecord.encode(message.letterHead, writer.uint32(90).fork()).ldelim();
    }
    if (message.createdAt !== 0) {
      writer.uint32(96).int64(message.createdAt);
    }
    if (message.updatedAt !== 0) {
      writer.uint32(104).int64(message.updatedAt);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WriterModel {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWriterModel();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.uuid = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.field = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.role = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.preferredGreeting = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.stayInformed = reader.int32() as any;
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.additionalQuestions.push(reader.string());
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.additionalDocuments.push(reader.string());
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.letterHead = DocumentRecord.decode(reader, reader.uint32());
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.createdAt = longToNumber(reader.int64() as Long);
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.updatedAt = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): WriterModel {
    return {
      uuid: isSet(object.uuid) ? String(object.uuid) : "",
      field: isSet(object.field) ? String(object.field) : "",
      role: isSet(object.role) ? String(object.role) : "",
      status: isSet(object.status) ? userStatusFromJSON(object.status) : 0,
      preferredGreeting: isSet(object.preferred_greeting) ? String(object.preferred_greeting) : "",
      organization: isSet(object.organization) ? String(object.organization) : "",
      stayInformed: isSet(object.stay_informed) ? booleanFromJSON(object.stay_informed) : 0,
      additionalQuestions: Array.isArray(object?.additional_questions)
        ? object.additional_questions.map((e: any) => String(e))
        : [],
      additionalDocuments: Array.isArray(object?.additional_documents)
        ? object.additional_documents.map((e: any) => String(e))
        : [],
      letterHead: isSet(object.letter_head) ? DocumentRecord.fromJSON(object.letter_head) : undefined,
      createdAt: isSet(object.created_at) ? Number(object.created_at) : 0,
      updatedAt: isSet(object.updated_at) ? Number(object.updated_at) : 0,
    };
  },

  toJSON(message: WriterModel): unknown {
    const obj: any = {};
    message.uuid !== undefined && (obj.uuid = message.uuid);
    message.field !== undefined && (obj.field = message.field);
    message.role !== undefined && (obj.role = message.role);
    message.status !== undefined && (obj.status = userStatusToJSON(message.status));
    message.preferredGreeting !== undefined && (obj.preferred_greeting = message.preferredGreeting);
    message.organization !== undefined && (obj.organization = message.organization);
    message.stayInformed !== undefined && (obj.stay_informed = booleanToJSON(message.stayInformed));
    if (message.additionalQuestions) {
      obj.additional_questions = message.additionalQuestions.map((e) => e);
    } else {
      obj.additional_questions = [];
    }
    if (message.additionalDocuments) {
      obj.additional_documents = message.additionalDocuments.map((e) => e);
    } else {
      obj.additional_documents = [];
    }
    message.letterHead !== undefined &&
      (obj.letter_head = message.letterHead ? DocumentRecord.toJSON(message.letterHead) : undefined);
    message.createdAt !== undefined && (obj.created_at = Math.round(message.createdAt));
    message.updatedAt !== undefined && (obj.updated_at = Math.round(message.updatedAt));
    return obj;
  },

  create<I extends Exact<DeepPartial<WriterModel>, I>>(base?: I): WriterModel {
    return WriterModel.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<WriterModel>, I>>(object: I): WriterModel {
    const message = createBaseWriterModel();
    message.uuid = object.uuid ?? "";
    message.field = object.field ?? "";
    message.role = object.role ?? "";
    message.status = object.status ?? 0;
    message.preferredGreeting = object.preferredGreeting ?? "";
    message.organization = object.organization ?? "";
    message.stayInformed = object.stayInformed ?? 0;
    message.additionalQuestions = object.additionalQuestions?.map((e) => e) || [];
    message.additionalDocuments = object.additionalDocuments?.map((e) => e) || [];
    message.letterHead = (object.letterHead !== undefined && object.letterHead !== null)
      ? DocumentRecord.fromPartial(object.letterHead)
      : undefined;
    message.createdAt = object.createdAt ?? 0;
    message.updatedAt = object.updatedAt ?? 0;
    return message;
  },
};

function createBaseApplicantModel(): ApplicantModel {
  return { uuid: "", credits: 0, paidCredits: 0, creditsRefreshDate: 0, createdAt: 0, updatedAt: 0 };
}

export const ApplicantModel = {
  encode(message: ApplicantModel, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.uuid !== "") {
      writer.uint32(10).string(message.uuid);
    }
    if (message.credits !== 0) {
      writer.uint32(16).int64(message.credits);
    }
    if (message.paidCredits !== 0) {
      writer.uint32(24).int64(message.paidCredits);
    }
    if (message.creditsRefreshDate !== 0) {
      writer.uint32(32).int64(message.creditsRefreshDate);
    }
    if (message.createdAt !== 0) {
      writer.uint32(40).int64(message.createdAt);
    }
    if (message.updatedAt !== 0) {
      writer.uint32(48).int64(message.updatedAt);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ApplicantModel {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseApplicantModel();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.uuid = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.credits = longToNumber(reader.int64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.paidCredits = longToNumber(reader.int64() as Long);
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.creditsRefreshDate = longToNumber(reader.int64() as Long);
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.createdAt = longToNumber(reader.int64() as Long);
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.updatedAt = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ApplicantModel {
    return {
      uuid: isSet(object.uuid) ? String(object.uuid) : "",
      credits: isSet(object.credits) ? Number(object.credits) : 0,
      paidCredits: isSet(object.paid_credits) ? Number(object.paid_credits) : 0,
      creditsRefreshDate: isSet(object.credits_refresh_date) ? Number(object.credits_refresh_date) : 0,
      createdAt: isSet(object.created_at) ? Number(object.created_at) : 0,
      updatedAt: isSet(object.updated_at) ? Number(object.updated_at) : 0,
    };
  },

  toJSON(message: ApplicantModel): unknown {
    const obj: any = {};
    message.uuid !== undefined && (obj.uuid = message.uuid);
    message.credits !== undefined && (obj.credits = Math.round(message.credits));
    message.paidCredits !== undefined && (obj.paid_credits = Math.round(message.paidCredits));
    message.creditsRefreshDate !== undefined && (obj.credits_refresh_date = Math.round(message.creditsRefreshDate));
    message.createdAt !== undefined && (obj.created_at = Math.round(message.createdAt));
    message.updatedAt !== undefined && (obj.updated_at = Math.round(message.updatedAt));
    return obj;
  },

  create<I extends Exact<DeepPartial<ApplicantModel>, I>>(base?: I): ApplicantModel {
    return ApplicantModel.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ApplicantModel>, I>>(object: I): ApplicantModel {
    const message = createBaseApplicantModel();
    message.uuid = object.uuid ?? "";
    message.credits = object.credits ?? 0;
    message.paidCredits = object.paidCredits ?? 0;
    message.creditsRefreshDate = object.creditsRefreshDate ?? 0;
    message.createdAt = object.createdAt ?? 0;
    message.updatedAt = object.updatedAt ?? 0;
    return message;
  },
};

function createBaseCreateUserRequest(): CreateUserRequest {
  return { email: "", name: "", passwordHash: "" };
}

export const CreateUserRequest = {
  encode(message: CreateUserRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.email !== "") {
      writer.uint32(10).string(message.email);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.passwordHash !== "") {
      writer.uint32(26).string(message.passwordHash);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateUserRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateUserRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.email = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.passwordHash = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateUserRequest {
    return {
      email: isSet(object.email) ? String(object.email) : "",
      name: isSet(object.name) ? String(object.name) : "",
      passwordHash: isSet(object.password_hash) ? String(object.password_hash) : "",
    };
  },

  toJSON(message: CreateUserRequest): unknown {
    const obj: any = {};
    message.email !== undefined && (obj.email = message.email);
    message.name !== undefined && (obj.name = message.name);
    message.passwordHash !== undefined && (obj.password_hash = message.passwordHash);
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateUserRequest>, I>>(base?: I): CreateUserRequest {
    return CreateUserRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<CreateUserRequest>, I>>(object: I): CreateUserRequest {
    const message = createBaseCreateUserRequest();
    message.email = object.email ?? "";
    message.name = object.name ?? "";
    message.passwordHash = object.passwordHash ?? "";
    return message;
  },
};

function createBaseGetUserRequest(): GetUserRequest {
  return { identifier: "" };
}

export const GetUserRequest = {
  encode(message: GetUserRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.identifier !== "") {
      writer.uint32(10).string(message.identifier);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetUserRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetUserRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.identifier = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetUserRequest {
    return { identifier: isSet(object.identifier) ? String(object.identifier) : "" };
  },

  toJSON(message: GetUserRequest): unknown {
    const obj: any = {};
    message.identifier !== undefined && (obj.identifier = message.identifier);
    return obj;
  },

  create<I extends Exact<DeepPartial<GetUserRequest>, I>>(base?: I): GetUserRequest {
    return GetUserRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GetUserRequest>, I>>(object: I): GetUserRequest {
    const message = createBaseGetUserRequest();
    message.identifier = object.identifier ?? "";
    return message;
  },
};

function createBaseCreateWriterRequest(): CreateWriterRequest {
  return { uuid: "", field: "", role: "", preferredGreeting: "", organization: "", stayInformed: 0 };
}

export const CreateWriterRequest = {
  encode(message: CreateWriterRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.uuid !== "") {
      writer.uint32(10).string(message.uuid);
    }
    if (message.field !== "") {
      writer.uint32(18).string(message.field);
    }
    if (message.role !== "") {
      writer.uint32(26).string(message.role);
    }
    if (message.preferredGreeting !== "") {
      writer.uint32(34).string(message.preferredGreeting);
    }
    if (message.organization !== "") {
      writer.uint32(42).string(message.organization);
    }
    if (message.stayInformed !== 0) {
      writer.uint32(48).int32(message.stayInformed);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateWriterRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateWriterRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.uuid = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.field = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.role = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.preferredGreeting = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.stayInformed = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateWriterRequest {
    return {
      uuid: isSet(object.uuid) ? String(object.uuid) : "",
      field: isSet(object.field) ? String(object.field) : "",
      role: isSet(object.role) ? String(object.role) : "",
      preferredGreeting: isSet(object.preferred_greeting) ? String(object.preferred_greeting) : "",
      organization: isSet(object.organization) ? String(object.organization) : "",
      stayInformed: isSet(object.stay_informed) ? booleanFromJSON(object.stay_informed) : 0,
    };
  },

  toJSON(message: CreateWriterRequest): unknown {
    const obj: any = {};
    message.uuid !== undefined && (obj.uuid = message.uuid);
    message.field !== undefined && (obj.field = message.field);
    message.role !== undefined && (obj.role = message.role);
    message.preferredGreeting !== undefined && (obj.preferred_greeting = message.preferredGreeting);
    message.organization !== undefined && (obj.organization = message.organization);
    message.stayInformed !== undefined && (obj.stay_informed = booleanToJSON(message.stayInformed));
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateWriterRequest>, I>>(base?: I): CreateWriterRequest {
    return CreateWriterRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<CreateWriterRequest>, I>>(object: I): CreateWriterRequest {
    const message = createBaseCreateWriterRequest();
    message.uuid = object.uuid ?? "";
    message.field = object.field ?? "";
    message.role = object.role ?? "";
    message.preferredGreeting = object.preferredGreeting ?? "";
    message.organization = object.organization ?? "";
    message.stayInformed = object.stayInformed ?? 0;
    return message;
  },
};

function createBaseEditWriterRequest(): EditWriterRequest {
  return { uuid: "", writer: undefined };
}

export const EditWriterRequest = {
  encode(message: EditWriterRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.uuid !== "") {
      writer.uint32(10).string(message.uuid);
    }
    if (message.writer !== undefined) {
      WriterModel.encode(message.writer, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EditWriterRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEditWriterRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.uuid = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.writer = WriterModel.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EditWriterRequest {
    return {
      uuid: isSet(object.uuid) ? String(object.uuid) : "",
      writer: isSet(object.writer) ? WriterModel.fromJSON(object.writer) : undefined,
    };
  },

  toJSON(message: EditWriterRequest): unknown {
    const obj: any = {};
    message.uuid !== undefined && (obj.uuid = message.uuid);
    message.writer !== undefined && (obj.writer = message.writer ? WriterModel.toJSON(message.writer) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<EditWriterRequest>, I>>(base?: I): EditWriterRequest {
    return EditWriterRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<EditWriterRequest>, I>>(object: I): EditWriterRequest {
    const message = createBaseEditWriterRequest();
    message.uuid = object.uuid ?? "";
    message.writer = (object.writer !== undefined && object.writer !== null)
      ? WriterModel.fromPartial(object.writer)
      : undefined;
    return message;
  },
};

function createBaseEditUserRequest(): EditUserRequest {
  return { uuid: "", user: undefined };
}

export const EditUserRequest = {
  encode(message: EditUserRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.uuid !== "") {
      writer.uint32(10).string(message.uuid);
    }
    if (message.user !== undefined) {
      UserModel.encode(message.user, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EditUserRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEditUserRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.uuid = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.user = UserModel.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EditUserRequest {
    return {
      uuid: isSet(object.uuid) ? String(object.uuid) : "",
      user: isSet(object.user) ? UserModel.fromJSON(object.user) : undefined,
    };
  },

  toJSON(message: EditUserRequest): unknown {
    const obj: any = {};
    message.uuid !== undefined && (obj.uuid = message.uuid);
    message.user !== undefined && (obj.user = message.user ? UserModel.toJSON(message.user) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<EditUserRequest>, I>>(base?: I): EditUserRequest {
    return EditUserRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<EditUserRequest>, I>>(object: I): EditUserRequest {
    const message = createBaseEditUserRequest();
    message.uuid = object.uuid ?? "";
    message.user = (object.user !== undefined && object.user !== null) ? UserModel.fromPartial(object.user) : undefined;
    return message;
  },
};

function createBaseCreateApplicantRequest(): CreateApplicantRequest {
  return { uuid: "" };
}

export const CreateApplicantRequest = {
  encode(message: CreateApplicantRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.uuid !== "") {
      writer.uint32(10).string(message.uuid);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateApplicantRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateApplicantRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.uuid = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateApplicantRequest {
    return { uuid: isSet(object.uuid) ? String(object.uuid) : "" };
  },

  toJSON(message: CreateApplicantRequest): unknown {
    const obj: any = {};
    message.uuid !== undefined && (obj.uuid = message.uuid);
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateApplicantRequest>, I>>(base?: I): CreateApplicantRequest {
    return CreateApplicantRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<CreateApplicantRequest>, I>>(object: I): CreateApplicantRequest {
    const message = createBaseCreateApplicantRequest();
    message.uuid = object.uuid ?? "";
    return message;
  },
};

function createBaseCreateApplicantResponse(): CreateApplicantResponse {
  return { applicant: undefined };
}

export const CreateApplicantResponse = {
  encode(message: CreateApplicantResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.applicant !== undefined) {
      ApplicantModel.encode(message.applicant, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateApplicantResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateApplicantResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.applicant = ApplicantModel.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateApplicantResponse {
    return { applicant: isSet(object.applicant) ? ApplicantModel.fromJSON(object.applicant) : undefined };
  },

  toJSON(message: CreateApplicantResponse): unknown {
    const obj: any = {};
    message.applicant !== undefined &&
      (obj.applicant = message.applicant ? ApplicantModel.toJSON(message.applicant) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateApplicantResponse>, I>>(base?: I): CreateApplicantResponse {
    return CreateApplicantResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<CreateApplicantResponse>, I>>(object: I): CreateApplicantResponse {
    const message = createBaseCreateApplicantResponse();
    message.applicant = (object.applicant !== undefined && object.applicant !== null)
      ? ApplicantModel.fromPartial(object.applicant)
      : undefined;
    return message;
  },
};

function createBaseGetApplicantRequest(): GetApplicantRequest {
  return { uuid: "" };
}

export const GetApplicantRequest = {
  encode(message: GetApplicantRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.uuid !== "") {
      writer.uint32(10).string(message.uuid);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetApplicantRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetApplicantRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.uuid = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetApplicantRequest {
    return { uuid: isSet(object.uuid) ? String(object.uuid) : "" };
  },

  toJSON(message: GetApplicantRequest): unknown {
    const obj: any = {};
    message.uuid !== undefined && (obj.uuid = message.uuid);
    return obj;
  },

  create<I extends Exact<DeepPartial<GetApplicantRequest>, I>>(base?: I): GetApplicantRequest {
    return GetApplicantRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GetApplicantRequest>, I>>(object: I): GetApplicantRequest {
    const message = createBaseGetApplicantRequest();
    message.uuid = object.uuid ?? "";
    return message;
  },
};

function createBaseGetApplicantResponse(): GetApplicantResponse {
  return { applicant: undefined };
}

export const GetApplicantResponse = {
  encode(message: GetApplicantResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.applicant !== undefined) {
      ApplicantModel.encode(message.applicant, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetApplicantResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetApplicantResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.applicant = ApplicantModel.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetApplicantResponse {
    return { applicant: isSet(object.applicant) ? ApplicantModel.fromJSON(object.applicant) : undefined };
  },

  toJSON(message: GetApplicantResponse): unknown {
    const obj: any = {};
    message.applicant !== undefined &&
      (obj.applicant = message.applicant ? ApplicantModel.toJSON(message.applicant) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<GetApplicantResponse>, I>>(base?: I): GetApplicantResponse {
    return GetApplicantResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GetApplicantResponse>, I>>(object: I): GetApplicantResponse {
    const message = createBaseGetApplicantResponse();
    message.applicant = (object.applicant !== undefined && object.applicant !== null)
      ? ApplicantModel.fromPartial(object.applicant)
      : undefined;
    return message;
  },
};

function createBaseEditApplicantRequest(): EditApplicantRequest {
  return { applicant: undefined };
}

export const EditApplicantRequest = {
  encode(message: EditApplicantRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.applicant !== undefined) {
      ApplicantModel.encode(message.applicant, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EditApplicantRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEditApplicantRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.applicant = ApplicantModel.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EditApplicantRequest {
    return { applicant: isSet(object.applicant) ? ApplicantModel.fromJSON(object.applicant) : undefined };
  },

  toJSON(message: EditApplicantRequest): unknown {
    const obj: any = {};
    message.applicant !== undefined &&
      (obj.applicant = message.applicant ? ApplicantModel.toJSON(message.applicant) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<EditApplicantRequest>, I>>(base?: I): EditApplicantRequest {
    return EditApplicantRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<EditApplicantRequest>, I>>(object: I): EditApplicantRequest {
    const message = createBaseEditApplicantRequest();
    message.applicant = (object.applicant !== undefined && object.applicant !== null)
      ? ApplicantModel.fromPartial(object.applicant)
      : undefined;
    return message;
  },
};

function createBaseEditApplicantResponse(): EditApplicantResponse {
  return {};
}

export const EditApplicantResponse = {
  encode(_: EditApplicantResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EditApplicantResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEditApplicantResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): EditApplicantResponse {
    return {};
  },

  toJSON(_: EditApplicantResponse): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<EditApplicantResponse>, I>>(base?: I): EditApplicantResponse {
    return EditApplicantResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<EditApplicantResponse>, I>>(_: I): EditApplicantResponse {
    const message = createBaseEditApplicantResponse();
    return message;
  },
};

function createBasePrefixQueryRequest(): PrefixQueryRequest {
  return { table: "", path: "", prefix: "" };
}

export const PrefixQueryRequest = {
  encode(message: PrefixQueryRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.table !== "") {
      writer.uint32(10).string(message.table);
    }
    if (message.path !== "") {
      writer.uint32(18).string(message.path);
    }
    if (message.prefix !== "") {
      writer.uint32(26).string(message.prefix);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PrefixQueryRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePrefixQueryRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.table = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.path = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.prefix = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PrefixQueryRequest {
    return {
      table: isSet(object.table) ? String(object.table) : "",
      path: isSet(object.path) ? String(object.path) : "",
      prefix: isSet(object.prefix) ? String(object.prefix) : "",
    };
  },

  toJSON(message: PrefixQueryRequest): unknown {
    const obj: any = {};
    message.table !== undefined && (obj.table = message.table);
    message.path !== undefined && (obj.path = message.path);
    message.prefix !== undefined && (obj.prefix = message.prefix);
    return obj;
  },

  create<I extends Exact<DeepPartial<PrefixQueryRequest>, I>>(base?: I): PrefixQueryRequest {
    return PrefixQueryRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PrefixQueryRequest>, I>>(object: I): PrefixQueryRequest {
    const message = createBasePrefixQueryRequest();
    message.table = object.table ?? "";
    message.path = object.path ?? "";
    message.prefix = object.prefix ?? "";
    return message;
  },
};

function createBasePrefixQueryResponse(): PrefixQueryResponse {
  return { users: undefined, writers: undefined };
}

export const PrefixQueryResponse = {
  encode(message: PrefixQueryResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.users !== undefined) {
      ListOfUsers.encode(message.users, writer.uint32(10).fork()).ldelim();
    }
    if (message.writers !== undefined) {
      ListOfWriters.encode(message.writers, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PrefixQueryResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePrefixQueryResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.users = ListOfUsers.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.writers = ListOfWriters.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PrefixQueryResponse {
    return {
      users: isSet(object.users) ? ListOfUsers.fromJSON(object.users) : undefined,
      writers: isSet(object.writers) ? ListOfWriters.fromJSON(object.writers) : undefined,
    };
  },

  toJSON(message: PrefixQueryResponse): unknown {
    const obj: any = {};
    message.users !== undefined && (obj.users = message.users ? ListOfUsers.toJSON(message.users) : undefined);
    message.writers !== undefined &&
      (obj.writers = message.writers ? ListOfWriters.toJSON(message.writers) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<PrefixQueryResponse>, I>>(base?: I): PrefixQueryResponse {
    return PrefixQueryResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PrefixQueryResponse>, I>>(object: I): PrefixQueryResponse {
    const message = createBasePrefixQueryResponse();
    message.users = (object.users !== undefined && object.users !== null)
      ? ListOfUsers.fromPartial(object.users)
      : undefined;
    message.writers = (object.writers !== undefined && object.writers !== null)
      ? ListOfWriters.fromPartial(object.writers)
      : undefined;
    return message;
  },
};

function createBaseListOfUsers(): ListOfUsers {
  return { users: [] };
}

export const ListOfUsers = {
  encode(message: ListOfUsers, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.users) {
      UserModel.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListOfUsers {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListOfUsers();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.users.push(UserModel.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListOfUsers {
    return { users: Array.isArray(object?.users) ? object.users.map((e: any) => UserModel.fromJSON(e)) : [] };
  },

  toJSON(message: ListOfUsers): unknown {
    const obj: any = {};
    if (message.users) {
      obj.users = message.users.map((e) => e ? UserModel.toJSON(e) : undefined);
    } else {
      obj.users = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListOfUsers>, I>>(base?: I): ListOfUsers {
    return ListOfUsers.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ListOfUsers>, I>>(object: I): ListOfUsers {
    const message = createBaseListOfUsers();
    message.users = object.users?.map((e) => UserModel.fromPartial(e)) || [];
    return message;
  },
};

function createBaseListOfWriters(): ListOfWriters {
  return { writers: [] };
}

export const ListOfWriters = {
  encode(message: ListOfWriters, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.writers) {
      WriterModel.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListOfWriters {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListOfWriters();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.writers.push(WriterModel.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListOfWriters {
    return { writers: Array.isArray(object?.writers) ? object.writers.map((e: any) => WriterModel.fromJSON(e)) : [] };
  },

  toJSON(message: ListOfWriters): unknown {
    const obj: any = {};
    if (message.writers) {
      obj.writers = message.writers.map((e) => e ? WriterModel.toJSON(e) : undefined);
    } else {
      obj.writers = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListOfWriters>, I>>(base?: I): ListOfWriters {
    return ListOfWriters.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ListOfWriters>, I>>(object: I): ListOfWriters {
    const message = createBaseListOfWriters();
    message.writers = object.writers?.map((e) => WriterModel.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetAllByEnterpriseIdRequest(): GetAllByEnterpriseIdRequest {
  return { enterpriseId: "" };
}

export const GetAllByEnterpriseIdRequest = {
  encode(message: GetAllByEnterpriseIdRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.enterpriseId !== "") {
      writer.uint32(10).string(message.enterpriseId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetAllByEnterpriseIdRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetAllByEnterpriseIdRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.enterpriseId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetAllByEnterpriseIdRequest {
    return { enterpriseId: isSet(object.enterprise_id) ? String(object.enterprise_id) : "" };
  },

  toJSON(message: GetAllByEnterpriseIdRequest): unknown {
    const obj: any = {};
    message.enterpriseId !== undefined && (obj.enterprise_id = message.enterpriseId);
    return obj;
  },

  create<I extends Exact<DeepPartial<GetAllByEnterpriseIdRequest>, I>>(base?: I): GetAllByEnterpriseIdRequest {
    return GetAllByEnterpriseIdRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GetAllByEnterpriseIdRequest>, I>>(object: I): GetAllByEnterpriseIdRequest {
    const message = createBaseGetAllByEnterpriseIdRequest();
    message.enterpriseId = object.enterpriseId ?? "";
    return message;
  },
};

function createBaseGetAllByEnterpriseIdResponse(): GetAllByEnterpriseIdResponse {
  return { users: [], writers: [] };
}

export const GetAllByEnterpriseIdResponse = {
  encode(message: GetAllByEnterpriseIdResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.users) {
      UserModel.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.writers) {
      WriterModel.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetAllByEnterpriseIdResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetAllByEnterpriseIdResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.users.push(UserModel.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.writers.push(WriterModel.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetAllByEnterpriseIdResponse {
    return {
      users: Array.isArray(object?.users) ? object.users.map((e: any) => UserModel.fromJSON(e)) : [],
      writers: Array.isArray(object?.writers) ? object.writers.map((e: any) => WriterModel.fromJSON(e)) : [],
    };
  },

  toJSON(message: GetAllByEnterpriseIdResponse): unknown {
    const obj: any = {};
    if (message.users) {
      obj.users = message.users.map((e) => e ? UserModel.toJSON(e) : undefined);
    } else {
      obj.users = [];
    }
    if (message.writers) {
      obj.writers = message.writers.map((e) => e ? WriterModel.toJSON(e) : undefined);
    } else {
      obj.writers = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetAllByEnterpriseIdResponse>, I>>(base?: I): GetAllByEnterpriseIdResponse {
    return GetAllByEnterpriseIdResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GetAllByEnterpriseIdResponse>, I>>(object: I): GetAllByEnterpriseIdResponse {
    const message = createBaseGetAllByEnterpriseIdResponse();
    message.users = object.users?.map((e) => UserModel.fromPartial(e)) || [];
    message.writers = object.writers?.map((e) => WriterModel.fromPartial(e)) || [];
    return message;
  },
};

export interface ArtemisService {
  /** Users POST */
  CreateUser(request: CreateUserRequest): Promise<UserModel>;
  /** Users GET */
  GetUser(request: GetUserRequest): Promise<UserModel>;
  GetUserByID(request: GetUserRequest): Promise<UserModel>;
  GetUserByEmail(request: GetUserRequest): Promise<UserModel>;
  /** Users DELETE */
  DeleteUser(request: GetUserRequest): Promise<UserModel>;
  /** Users PUT */
  EditUser(request: UserModel): Promise<UserModel>;
  /** Writers POST */
  CreateWriter(request: CreateWriterRequest): Promise<WriterModel>;
  /** Writers GET */
  GetWriter(request: GetUserRequest): Promise<WriterModel>;
  GetWriterByUsername(request: GetUserRequest): Promise<WriterModel>;
  GetWriterByUserID(request: GetUserRequest): Promise<WriterModel>;
  /** Writers DELETE */
  DeleteWriter(request: GetUserRequest): Promise<WriterModel>;
  /** Writers PUT */
  EditWriter(request: EditWriterRequest): Promise<WriterModel>;
  CreateApplicant(request: CreateApplicantRequest): Promise<CreateApplicantResponse>;
  GetApplicant(request: GetApplicantRequest): Promise<GetApplicantResponse>;
  EditApplicant(request: EditApplicantRequest): Promise<EditApplicantResponse>;
  /** GET */
  PrefixQuery(request: PrefixQueryRequest): Promise<PrefixQueryResponse>;
  GetAllByEnterpriseId(request: GetAllByEnterpriseIdRequest): Promise<GetAllByEnterpriseIdResponse>;
}

export class ArtemisServiceClientImpl implements ArtemisService {
  private readonly rpc: Rpc;
  private readonly service: string;
  constructor(rpc: Rpc, opts?: { service?: string }) {
    this.service = opts?.service || "artemis.ArtemisService";
    this.rpc = rpc;
    this.CreateUser = this.CreateUser.bind(this);
    this.GetUser = this.GetUser.bind(this);
    this.GetUserByID = this.GetUserByID.bind(this);
    this.GetUserByEmail = this.GetUserByEmail.bind(this);
    this.DeleteUser = this.DeleteUser.bind(this);
    this.EditUser = this.EditUser.bind(this);
    this.CreateWriter = this.CreateWriter.bind(this);
    this.GetWriter = this.GetWriter.bind(this);
    this.GetWriterByUsername = this.GetWriterByUsername.bind(this);
    this.GetWriterByUserID = this.GetWriterByUserID.bind(this);
    this.DeleteWriter = this.DeleteWriter.bind(this);
    this.EditWriter = this.EditWriter.bind(this);
    this.CreateApplicant = this.CreateApplicant.bind(this);
    this.GetApplicant = this.GetApplicant.bind(this);
    this.EditApplicant = this.EditApplicant.bind(this);
    this.PrefixQuery = this.PrefixQuery.bind(this);
    this.GetAllByEnterpriseId = this.GetAllByEnterpriseId.bind(this);
  }
  CreateUser(request: CreateUserRequest): Promise<UserModel> {
    const data = CreateUserRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "CreateUser", data);
    return promise.then((data) => UserModel.decode(_m0.Reader.create(data)));
  }

  GetUser(request: GetUserRequest): Promise<UserModel> {
    const data = GetUserRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "GetUser", data);
    return promise.then((data) => UserModel.decode(_m0.Reader.create(data)));
  }

  GetUserByID(request: GetUserRequest): Promise<UserModel> {
    const data = GetUserRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "GetUserByID", data);
    return promise.then((data) => UserModel.decode(_m0.Reader.create(data)));
  }

  GetUserByEmail(request: GetUserRequest): Promise<UserModel> {
    const data = GetUserRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "GetUserByEmail", data);
    return promise.then((data) => UserModel.decode(_m0.Reader.create(data)));
  }

  DeleteUser(request: GetUserRequest): Promise<UserModel> {
    const data = GetUserRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "DeleteUser", data);
    return promise.then((data) => UserModel.decode(_m0.Reader.create(data)));
  }

  EditUser(request: UserModel): Promise<UserModel> {
    const data = UserModel.encode(request).finish();
    const promise = this.rpc.request(this.service, "EditUser", data);
    return promise.then((data) => UserModel.decode(_m0.Reader.create(data)));
  }

  CreateWriter(request: CreateWriterRequest): Promise<WriterModel> {
    const data = CreateWriterRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "CreateWriter", data);
    return promise.then((data) => WriterModel.decode(_m0.Reader.create(data)));
  }

  GetWriter(request: GetUserRequest): Promise<WriterModel> {
    const data = GetUserRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "GetWriter", data);
    return promise.then((data) => WriterModel.decode(_m0.Reader.create(data)));
  }

  GetWriterByUsername(request: GetUserRequest): Promise<WriterModel> {
    const data = GetUserRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "GetWriterByUsername", data);
    return promise.then((data) => WriterModel.decode(_m0.Reader.create(data)));
  }

  GetWriterByUserID(request: GetUserRequest): Promise<WriterModel> {
    const data = GetUserRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "GetWriterByUserID", data);
    return promise.then((data) => WriterModel.decode(_m0.Reader.create(data)));
  }

  DeleteWriter(request: GetUserRequest): Promise<WriterModel> {
    const data = GetUserRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "DeleteWriter", data);
    return promise.then((data) => WriterModel.decode(_m0.Reader.create(data)));
  }

  EditWriter(request: EditWriterRequest): Promise<WriterModel> {
    const data = EditWriterRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "EditWriter", data);
    return promise.then((data) => WriterModel.decode(_m0.Reader.create(data)));
  }

  CreateApplicant(request: CreateApplicantRequest): Promise<CreateApplicantResponse> {
    const data = CreateApplicantRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "CreateApplicant", data);
    return promise.then((data) => CreateApplicantResponse.decode(_m0.Reader.create(data)));
  }

  GetApplicant(request: GetApplicantRequest): Promise<GetApplicantResponse> {
    const data = GetApplicantRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "GetApplicant", data);
    return promise.then((data) => GetApplicantResponse.decode(_m0.Reader.create(data)));
  }

  EditApplicant(request: EditApplicantRequest): Promise<EditApplicantResponse> {
    const data = EditApplicantRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "EditApplicant", data);
    return promise.then((data) => EditApplicantResponse.decode(_m0.Reader.create(data)));
  }

  PrefixQuery(request: PrefixQueryRequest): Promise<PrefixQueryResponse> {
    const data = PrefixQueryRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "PrefixQuery", data);
    return promise.then((data) => PrefixQueryResponse.decode(_m0.Reader.create(data)));
  }

  GetAllByEnterpriseId(request: GetAllByEnterpriseIdRequest): Promise<GetAllByEnterpriseIdResponse> {
    const data = GetAllByEnterpriseIdRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "GetAllByEnterpriseId", data);
    return promise.then((data) => GetAllByEnterpriseIdResponse.decode(_m0.Reader.create(data)));
  }
}

interface Rpc {
  request(service: string, method: string, data: Uint8Array): Promise<Uint8Array>;
}

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var tsProtoGlobalThis: any = (() => {
  if (typeof globalThis !== "undefined") {
    return globalThis;
  }
  if (typeof self !== "undefined") {
    return self;
  }
  if (typeof window !== "undefined") {
    return window;
  }
  if (typeof global !== "undefined") {
    return global;
  }
  throw "Unable to locate global object";
})();

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new tsProtoGlobalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
