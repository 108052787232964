import { useState } from "react";
import { useNavigate } from "react-router";
import { useQueryClient } from '@tanstack/react-query';
import { CombinedModel, useEnterpriseFromUUIDQuery, useGetAllByEnterpriseIdQuery, useKratosLogoutMutation, useUserQuery } from "@cosmogony/reffy-hooks";
import PrivacyFooter from "../components/PrivacyFooter";
import AddUserModal from "../components/AddUserModal";
import ContactModal from "../components/ContactModal";
import Loading from "../components/Loading";
import UserTable, { Column } from "../components/UserTable";
import { EnterpriseModel } from "@cosmogony/reffy-pb/apollo-svc/pkg/pb/apollo";
import Navbar from "../components/Navbar";


export default function UserManagement() {
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const logout = useKratosLogoutMutation();
    const userQuery = useUserQuery();
    const enterpriseQuery = useEnterpriseFromUUIDQuery();
    const enterpriseUsersQuery = useGetAllByEnterpriseIdQuery();
        
    const [showAddUserModal, setShowAddUserModal] = useState(false);
    const [tableQuery, setTableQuery] = useState('');
    const baseUrl = window.location.href.includes('localhost') || window.location.href.includes('127.0.0.1')
        ? 'http://localhost:3000'
        : `https://refbuilder.com`;
    
    
    if (userQuery.isLoading || enterpriseQuery.isLoading || enterpriseUsersQuery.isLoading || logout.isLoading) {
        return <Loading />
    }

    if (userQuery.isError || enterpriseQuery.isError || enterpriseUsersQuery.isError || logout.isError) {
        console.log("potential user query error inside usermanagement component:", userQuery.error)
        console.log("potential enterprise query error inside usermanagement component:", enterpriseQuery.error)
        console.log("potential enterprise users query error inside usermanagement component:", enterpriseUsersQuery.error)
        console.log("potential logout mutation error inside usermanagement component:", logout.error)
        window.location.replace(baseUrl);
    }

    const tableColumns: Column[] = [
        {id: 0, name: "Name", type: "text"},
        {id: 1, name: "Email", type: "text"},
        {id: 2, name: "User Type", type: "dropdown"},
        {id: 3, name: "Job Title/Role", type: "text"},
        {id: 4, name: "Intake Form URL", type: "copy text"},
        {id: 5, name: "Letter Stats", type: "text", subtext: "Total Requested - Completed - Declined"},
        {id: 6, name: "Last Login At", type: "text"}
    ];

    const enterpriseModel = enterpriseQuery.data as EnterpriseModel;
    const enterpriseUsersModel = enterpriseUsersQuery.data as CombinedModel[];
    const salesContact = {name: enterpriseModel.salesName, email: enterpriseModel.salesEmail, phone: enterpriseModel.salesNumber};

    enterpriseUsersModel.sort((user1, user2) => {
        const nameA = user1.name.toUpperCase(); 
        const nameB = user2.name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        } else if (nameA > nameB) {
          return 1;
        }
        return 0;
    });

    for (let row of enterpriseUsersModel) {
        if (enterpriseModel.owner === row.uuid) {
            row.userType = 'Owner';
        } else if (enterpriseModel.admins.includes(row.uuid)) {
            row.userType = 'Admin';
        }
    }
      
    return (
        <div className='bg-reffybg font-pressura min-h-screen relative flex flex-col w-full'>
            <div className='w-full min-[1440px]:w-[1440px] mx-auto'>
                <Navbar heading={'User Management'} />
                <main className="grow w-[95%] m-auto my-3 pb-[40px]">
                    <h1 className="min-[690px]:hidden text-2xl text-reffyblack text-center relative bottom-5">User Management</h1>
                    <h3 className="min-[690px]:hidden text-xl h-min text-center">{enterpriseModel.numFilledSeats}/{enterpriseModel.numSeats} Seats Filled</h3>
                    <a href="/" className="font-bold text-reffyblack"><span className="text-xl">←</span> DASHBOARD</a>
                    <div className="grid grid-cols-3 items-center mt-3 w-full">
                        <input value={tableQuery} onChange={(e) => setTableQuery(e.target.value)} className="appearance-none p-3 min-w-[250px] max-w-[350px] shadow-[0_1px_3px_rgba(0,0,0,0.1)]" type="text" placeholder="Search by name, email, role, etc..." title="Search by any column attribute like name, email, role, etc"/>
                        <h3 className="invisible min-[690px]:visible text-xl h-min text-center">{enterpriseModel.numFilledSeats}/{enterpriseModel.numSeats} Seats Filled</h3>
                        <button onClick={() => setShowAddUserModal(true)} className="sm:px-10 px-3 py-3 bg-reffygold font-bold text-reffyblack w-fit ml-auto">Add User</button>
                        {showAddUserModal && (
                            enterpriseModel.numFilledSeats === enterpriseModel.numSeats ? 
                            <ContactModal open={showAddUserModal} setOpen={setShowAddUserModal} numSeats={enterpriseModel.numSeats} salesContact={salesContact} /> :
                            <AddUserModal open={showAddUserModal} setOpen={setShowAddUserModal} enterprise={enterpriseModel} enterpriseUsers={enterpriseUsersModel} />
                        )}
                    </div>
                    <div className="my-4 w-full">
                        <UserTable query={tableQuery} cols={tableColumns} users={enterpriseUsersModel} enterprise={enterpriseModel} mutable className="w-full"/>
                    </div>
                </main>
            </div>
            <PrivacyFooter />
        </div>
    );
}

// const textFieldStyles = {
//     all: 'unset'
// };
