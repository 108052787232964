import { useState } from 'react';
import { useKratosLogoutMutation } from "@cosmogony/reffy-hooks";
import { Boolean } from '@cosmogony/reffy-pb/common/pb/common';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from "react-router";
import Loading from './Loading';


export default function Navbar(props: { heading?: string }) {
    const baseUrl = window.location.href.includes('localhost') || window.location.href.includes('127.0.0.1')
        ? 'http://localhost:3000'
        : `https://refbuilder.com`;
        
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const logout = useKratosLogoutMutation();
    

    if (logout.isLoading) {
        return <Loading />;
    }

    if (logout.isError) {
        console.log("Logout error inside Navbar component:", logout.error)
        window.location.replace(baseUrl);
    }


    return (   
        <header className="grow-0 w-[97%] mx-auto pt-[20px]">
                <div className="grid grid-cols-2 min-[690px]:grid-cols-3 items-center text-center">
                    <img
                        alt="Reference Builder Logo"
                        className="navbar-logo max-w-[250px] w-[150px] sm:w-[200px]"
                        src="/assets/images/logos/refbuilder-admin-logo.png" 
                    /> 
                    <h1 className="text-2xl text-reffyblack hidden min-[690px]:block">{props.heading}</h1>
                    <nav className="flex flex-row gap-5 justify-end">
                        <a className="text-reffygray font-bold uppercase whitespace-nowrap" href={`${baseUrl}/dashboard`}>Writer's Dashboard</a>
                        <button className="h-min text-reffygray uppercase font-bold" onClick={() => {
                            queryClient.removeQueries({
                                queryKey: ['user-authenticated'],
                            });
                            logout.mutate();
                            window.location.replace(baseUrl)
                        }}>Logout</button>
                    </nav>
                </div>
            </header>
        )
    }
