import { genericHandler } from '..';
import { UserModel } from '../../reffy-pb/artemis-svc/pkg/pb/artemis';
import {
    KratosEnterpriseSignupRequest,
    KratosSignupRequest,
} from '../../reffy-pb/olympus/pkg/services/kratos/pb/kratos';

export const KratosEnterpriseSignupMutationFcn = (
    user: KratosEnterpriseSignupRequest
): Promise<UserModel | object> => {
    return new Promise(async (resolve, reject) => {
        const response = await genericHandler(
            'POST',
            'include',
            '/kratos/enterprise_signup',
            KratosEnterpriseSignupRequest.toJSON(user) as Object
        );

        if (!response || response.error) {
            reject(response?.payload ?? 'unable to fulfill request');
        } else {
            resolve(UserModel.fromJSON(response.payload['user']));
        }
    });
};

export const KratosSignupMutationFcn = (user: KratosSignupRequest) => {
    return new Promise(async (resolve, reject) => {
        const response = await genericHandler(
            'POST',
            'include',
            '/kratos/signup',
            KratosSignupRequest.toJSON(user) as Object
        );

        if (!response || response.error) {
            reject(response?.payload ?? 'unable to fulfill request');
        } else {
            resolve(response.payload);
        }
    });
};
