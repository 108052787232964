import { useMutation, useQueryClient } from '@tanstack/react-query';
import { genericHandler } from '../../reffy-api';
import {
    KratosEnterpriseSignupRequest,
    KratosForgotPasswordRequest,
    KratosLoginRequest,
    KratosResetPasswordRequest,
    KratosSignupRequest,
    KratosVerifyUserRequest,
} from '../../reffy-pb/olympus/pkg/services/kratos/pb/kratos';
import {
    KratosEnterpriseSignupMutationFcn,
    KratosSignupMutationFcn,
} from '../../reffy-api/kratos/kratos_api';
import { UserModel } from '../../reffy-pb/artemis-svc/pkg/pb/artemis';

// Mutation to login the user
export function useKratosLoginMutation() {
    const queryClient = useQueryClient();
    return useMutation({
        mutationKey: ['login'],
        mutationFn: (credentials: KratosLoginRequest) => {
            return new Promise<Object | string>(async (resolve, reject) => {
                const response = await genericHandler(
                    'POST',
                    'include',
                    '/kratos/login',
                    KratosLoginRequest.toJSON(credentials) as Object
                );
                if (!credentials.identifier || !credentials.password) {
                    reject('username or password missing');
                } else if (!response || response.error) {
                    reject(response?.payload ?? 'unable to fulfill request');
                } else {
                    queryClient.invalidateQueries(['user-authenticated']);
                    resolve(response.payload);
                }
            });
        },

        onError: (err) => {
            console.error(err);
        },

        retry: 0,
    });
}

export function useKratosForgotMutation() {
    const queryClient = useQueryClient();
    return useMutation({
        mutationKey: ['forgot'],
        mutationFn: (req: KratosForgotPasswordRequest) => {
            return new Promise<Object | string>(async (resolve, reject) => {
                const response = await genericHandler(
                    'POST',
                    'include',
                    '/kratos/forgot',
                    KratosForgotPasswordRequest.toJSON(req) as Object
                );
                if (!req.identifier) {
                    reject('email or username missing');
                } else if (!response || response.error) {
                    reject(response?.payload ?? 'unable to fulfill request');
                } else {
                    queryClient.invalidateQueries(['user-authenticated']);
                    resolve(response.payload);
                }
            });
        },
    });
}

export function useKratosResetPasswordMutation() {
    const queryClient = useQueryClient();
    return useMutation({
        mutationKey: ['reset-password'],
        mutationFn: (req: KratosResetPasswordRequest) => {
            return new Promise<Object | string>(async (resolve, reject) => {
                const response = await genericHandler(
                    'POST',
                    'include',
                    '/kratos/reset-password',
                    KratosResetPasswordRequest.toJSON(req) as Object
                );
                if (response.error) {
                    reject(response.message);
                }
                resolve(response.payload);
            });
        },
        retry: 0,
        onError: (err) => {
            console.error(err);
        },

        onSuccess: () => {
            queryClient.invalidateQueries(['user-authenticated']);
        },
    });
}

// Mutation to signup the user
export function useKratosSignupMutation() {
    const queryClient = useQueryClient();
    return useMutation({
        mutationKey: ['signup'],
        mutationFn: async (user: KratosSignupRequest) => {
            return new Promise((resolve, reject) => {
                KratosSignupMutationFcn(user)
                    .catch((err) => reject(err))
                    .then((response) => {
                        queryClient.invalidateQueries(['user-authenticated']);
                        resolve(response);
                    });
            });
        },
        onError: (err) => {
            console.error(err);
        },
        retry: 0,
    });
}

// Mutation to logout the user
export function useKratosLogoutMutation() {
    const queryClient = useQueryClient();
    return useMutation({
        mutationKey: ['logout'],
        mutationFn: () => {
            return new Promise(async (resolve, reject) => {
                const response = await genericHandler(
                    'POST',
                    'include',
                    '/kratos/logout'
                );

                if (!response || response.error) {
                    reject(response?.payload ?? 'unable to fulfill request');
                } else {
                    resolve(response.payload);
                }
            });
        },

        onError: (err) => {
            console.error(err);
        },
        onSettled: () => {
            queryClient.invalidateQueries(['user-authenticated']);
        },
        retry: 0,
    });
}

export function useKratosVerifyMutation() {
    const queryClient = useQueryClient();
    return useMutation({
        mutationKey: ['verify'],
        mutationFn: (req: KratosVerifyUserRequest) => {
            return new Promise(async (resolve, reject) => {
                const response = await genericHandler(
                    'POST',
                    'include',
                    '/kratos/verify',
                    KratosVerifyUserRequest.toJSON(req) as Object
                );
                if (!response || response.error) {
                    reject(response?.payload ?? 'unable to fulfill request');
                } else {
                    resolve(response.payload);
                }
            });
        },

        onError: (err) => {
            console.error(err);
        },
        onSettled: () => {
            queryClient.invalidateQueries(['user-authenticated']);
        },
        retry: 0,
    });
}

// Mutation to signup enterprise users
export function useKratosEnterpriseSignupMutation() {
    return useMutation({
        mutationKey: ['signup'],
        mutationFn: async (user: KratosEnterpriseSignupRequest) => {
            return new Promise((resolve, reject) => {
                KratosEnterpriseSignupMutationFcn(user)
                    .catch((err) => reject(err))
                    .then((response) => {
                        resolve(response);
                    });
            });
        },
        onError: (err) => {
            console.error(err);
        },
        retry: 0,
    });
}
