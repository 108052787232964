import React from 'react';
import './Settings.css';
import { useNavigate } from 'react-router';
import { useQueryClient } from '@tanstack/react-query';
import { CombinedModel, useEnterpriseFromUUIDQuery, useGetAllByEnterpriseIdQuery, useKratosLogoutMutation, useUserQuery } from '@cosmogony/reffy-hooks';
import { EnterpriseModel } from '@cosmogony/reffy-pb/apollo-svc/pkg/pb/apollo';
import Navbar from '../../components/Navbar';
import PrivacyFooter from '../../components/PrivacyFooter';
import Loading from '../../components/Loading';

interface Contact {
  name: string;
  title?: string;
  email: string;
  phone?: string;
  type: string;
}

const SettingsPage: React.FC = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient(); 
  const logout = useKratosLogoutMutation();
  const userQuery = useUserQuery();
  const enterpriseQuery = useEnterpriseFromUUIDQuery();
  const enterpriseUsersQuery = useGetAllByEnterpriseIdQuery();

  const baseUrl = window.location.href.includes('localhost') || window.location.href.includes('127.0.0.1')
        ? 'http://localhost:3000'
        : `https://refbuilder.com`;

  if (userQuery.isLoading || enterpriseQuery.isLoading || enterpriseUsersQuery.isLoading || logout.isLoading) {
    return <Loading />
  }

  if (userQuery.isError || enterpriseQuery.isError || enterpriseUsersQuery.isError || logout.isError) {
    console.log("potential user query error inside settings component:", userQuery.error)
    console.log("potential enterprise query error inside settings component:", enterpriseQuery.error)
    console.log("potential enterprise users query error inside settings component:", enterpriseUsersQuery.error)
    console.log("potential logout mutation error inside settings component:", logout.error)
    window.location.replace(baseUrl);
}

  const enterpriseModel = enterpriseQuery.data as EnterpriseModel;
  const enterpriseUsersModel = enterpriseUsersQuery.data as CombinedModel[];
  
  const owners: Contact[] = [];
  const admins: Contact[] = [];
  const salesRep: Contact[] = [];

  const owner = enterpriseUsersModel.find(id => id.uuid === enterpriseModel.owner);
  if (owner) {
    owners.push({ name: owner.name, title: owner.role, email: owner.email, type: "Owner" });
  }
  
  enterpriseUsersModel.filter(id => enterpriseModel.admins.includes(id.uuid)).forEach(
    item => {
      admins.push({name: item.name, title: item.role, email: item.email, type: "Admin"})
    }
  )

  salesRep.push({name: enterpriseModel.salesName, email: enterpriseModel.salesEmail, phone: enterpriseModel.salesNumber, type: "Sales Representative"})

  return (
    <div className='pb-[40px] bg-reffybg font-pressura min-h-screen relative flex flex-col w-full'>
      <Navbar heading="Account Information" />
      <main className="w-[90%] m-auto">
        <a href="/" className="font-bold text-reffyblack font-pressura"><span className="text-xl">←</span> DASHBOARD</a>
        <h1 className="min-[690px]:hidden text-2xl text-reffyblack text-center relative top-5">Account Information</h1>
      </main>
      <div className="settings-container sm:w-3/4">
          <ContactSection title="Organization Information" data={{
            "Organization Name": enterpriseModel.name,
            "Owner / Organization Contact(s)": owners,
          }} />
          <ContactSection title="System Administrator(s)" data={{
            "System Administrator(s)": admins,
          }} />
          <ContactSection title="Subscription Info" data={{
            "Subscription Start Date": new Date(enterpriseModel.subscriptionStart * 1000).toLocaleDateString('en-CA'),
            "Subscription Renewal Date": new Date(enterpriseModel.subscriptionEnd * 1000).toLocaleDateString('en-CA'),
            "Total Number of Faculty Seats Filled": enterpriseModel.numFilledSeats,
            "Total Number of Faculty Seats": enterpriseModel.numSeats,
            "Contact Sales Representative": salesRep,
          }} />
          <ContactSection title="Customer Support / Contact" data={{
            "Technical support": 'support@refbuilder.com',
            "Subscription / Sales support": 'sales@refbuilder.com',
            "FAQ": 'sales@refbuilder.com',
          }} />
      </div>
      <PrivacyFooter />
    </div>
  );
};

const ContactSection = ({ title, data }) => {
  return (   
    <>
      <h1 className='section-header'>{title}</h1>
      <div className='section-contents'>
        {Object.entries(data).map(([key, value]) => (
          <React.Fragment key={key}>
            {Array.isArray(value) ? (
              <>
                {value.length > 0 && (
                    <div className='setting_field'>
                      <div className='title subtitle'>{key}</div> 
                      <div className='setting_display'></div>
                    </div>
                )}
                {value.map((contact, index) => (
                  <div key={index} className='contact'>
                      <div className='setting_field'>
                        <div className='title'>{contact.type} {value.length > 1 ? index + 1 : ''} Contact:</div>
                        <div className='setting_display'>
                          {contact.name && <div>Name: {contact.name}</div>}
                          {contact.title && <div>Title: {contact.title}</div>}
                          {contact.email && <div>Email: {contact.email}</div>}
                          {contact.phone && <div>Phone: {contact.phone}</div>}
                        </div>
                      </div>
                    </div>
                ))}
              </>
            ) : (
                <div className='setting_field' key={key}>
                  <div className='title'>{key}:</div>               
                  <div className='setting_display'>{typeof value === 'string' ? value : JSON.stringify(value)}</div>
                </div>
              
            )}            
          </React.Fragment>
        ))}
      </div>
    </>
  );
};

export default SettingsPage;

