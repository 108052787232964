import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { EditEnterpriseRequest, EnterpriseModel, UpdateEnterpriseMembershipRequest } from "../../reffy-pb/apollo-svc/pkg/pb/apollo";
import { genericHandler } from "../../reffy-api";

export function useEnterpriseFromUUIDQuery(uuid?: string) {
    const key = `enterprise-${uuid ?? "authenticated"}`;
    return useQuery({
        queryKey: [key],
        queryFn: async () => {
            return new Promise<EnterpriseModel>(async (resolve, reject) => {
                const response = await genericHandler(
                    'GET',
                    'include',
                    uuid ? `/apollo/get-uuid/${uuid}` : '/apollo/get-uuid'
                );
                if (!response || response.error) {
                    reject(response?.payload ?? 'unable to fulfill request');
                } else {
                    const enterprise = EnterpriseModel.fromJSON(response.payload);
                    resolve(enterprise);
                }
            });
        },

        retry: 0,
        retryOnMount: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,

        onError: (err) => {
            console.error(err);
        },
    });
}

export function useUpdateEnterpriseMembershipMutation() {
    const queryClient = useQueryClient();
    return useMutation({
        mutationKey: ['update-enterprise-member'],
        mutationFn: (req: UpdateEnterpriseMembershipRequest) => {
            return new Promise(async (resolve, reject) => {
                const response = await genericHandler(
                    'POST',
                    'include',
                    '/apollo/update-membership',
                    UpdateEnterpriseMembershipRequest.toJSON(req) as Object
                );

                if (!response || response.error) {
                    reject(response?.payload ?? 'unable to fulfill request');
                } else {
                    // await queryClient.invalidateQueries();
                    resolve(response.payload);
                }
            });
        },

        retry: 0,
        onError: (err) => {
            console.error(err);
        },
    }); 
}

export function useEditEnterpriseMutation() {
    const queryClient = useQueryClient();
    return useMutation({
        mutationKey: ['update-enterprise'],
        mutationFn: (req: EditEnterpriseRequest) => {
            return new Promise(async (resolve, reject) => {
                const response = await genericHandler(
                    'POST',
                    'include',
                    '/apollo/edit',
                    EditEnterpriseRequest.toJSON(req) as Object
                );

                if (!response || response.error) {
                    reject(response?.payload ?? 'unable to fulfill request');
                } else {
                    queryClient
                        .invalidateQueries(['writer-authenticated'])
                        .then(() => {
                            resolve(response.payload);
                        });
                }
            });
        },

        retry: 0,
        onError: (err) => {
            console.error(err);
        },
    }); 
}
