import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = ({ children }) => {
    const { pathname } = useLocation();

    useEffect(() => {
        document
            .getElementById('scroller')
            ?.scrollIntoView({ behavior: 'smooth' });
    }, [pathname]);

    return children || null;
};

export default ScrollToTop;
