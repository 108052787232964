import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import routes from './config/routes';
import Auth from './components/Auth';
import ScrollToTop from './components/ScrollToTop';
import {
    createBrowserRouter,
    createRoutesFromElements,
    Route,
    RouterProvider,
} from 'react-router-dom';

function App() {
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                staleTime: 1000 * 60 * 3, // 3 minutes, data is considered stale
                refetchOnWindowFocus: false,
                refetchOnMount: false,
            },
        },
    });

    const router = createBrowserRouter(
        createRoutesFromElements(
            routes.map((route, index) => (
                <Route
                    key={index}
                    path={route.path}
                    element={
                        <Auth protected={route.protected}>
                            <ScrollToTop>
                                <route.component />
                            </ScrollToTop>
                        </Auth>
                    }
                />
            ))
        )
    );
    return (
        <QueryClientProvider client={queryClient}>
            <RouterProvider router={router} />
            <ReactQueryDevtools />
        </QueryClientProvider>
    );
}

export default App;
