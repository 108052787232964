import { useKratosEnterpriseSignupMutation, useUpdateEnterpriseMembershipMutation, useEditEnterpriseMutation, CombinedModel } from '@cosmogony/reffy-hooks';
import { EnterpriseModel } from '@cosmogony/reffy-pb/apollo-svc/pkg/pb/apollo';
import { UserModel } from '@cosmogony/reffy-pb/artemis-svc/pkg/pb/artemis';
import { Boolean } from '@cosmogony/reffy-pb/common/pb/common';
import {useRef, useLayoutEffect, useEffect, useState, FormEvent} from 'react';
import Loading from './Loading';

interface AddUserModalProps {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  enterprise: EnterpriseModel;
  enterpriseUsers: CombinedModel[];
}

function generateDefaultPassword() {
	const length = 20;
	const characters =
		'0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz!@';
	return Array.from(crypto.getRandomValues(new Uint32Array(length)))
		.map((x) => characters[x % characters.length])
		.join('');
}

export default function AddUserModal({open, setOpen, enterprise, enterpriseUsers}: AddUserModalProps) {
  const dialog = useRef<HTMLDialogElement>(null);
  const [errorMessage, setErrorMessage] = useState('');
  const enterpriseMemberMutation = useUpdateEnterpriseMembershipMutation();
  const userSignUpMutation = useKratosEnterpriseSignupMutation();
  const enterpriseMutation = useEditEnterpriseMutation();
  
  useEffect(() => {
    if (userSignUpMutation.isSuccess && enterpriseMemberMutation.isSuccess && open) {
      setOpen(false);
      window.location.replace(window.location.href);
    }
  }, [userSignUpMutation, enterpriseMemberMutation, enterpriseMutation])

  useEffect(() => {
    document.addEventListener('keydown', (e) => {
      if (e.code === 'Escape') {
        setOpen(false)
      }
    })

    document.addEventListener('click', (e) => {
      const node = e.target as HTMLDialogElement;
      if (node.nodeName === 'DIALOG') {
        setOpen(false);
      }
    });
  }, [])
  
  useLayoutEffect(() => {
    if (open) {
      dialog.current?.showModal();
    } else {
      dialog.current?.close();
    }
  }, [open]);

  if (enterpriseMemberMutation.isLoading || userSignUpMutation.isLoading || enterpriseMutation.isLoading) {
    return <Loading />;
  }

  const addUser = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const name = formData.get('name')?.toString() as string;
    const email = formData.get('email')?.toString() as string;
    const userType = formData.get('userType')?.toString() as string;

    const userInEnterprise = enterpriseUsers.find((user) => user.email === email);
    if (userInEnterprise) {
      setErrorMessage("This user already exists in the enterprise.");
      return;
    }

    // update enterprise model if new user is an admin or owner
    try {
      const signUpResponse = await userSignUpMutation.mutateAsync({email: email, name: name, password: generateDefaultPassword(), euuid: enterprise.euuid, enterpriseName: enterprise.name});
      const newUser = signUpResponse as UserModel;
      // add user to admin list by modifying enterprise
      if (userType === 'Admin') {
        enterprise.admins.push(newUser.uuid);
        await enterpriseMutation.mutateAsync({
          euuid: enterprise.euuid, 
          name: enterprise.name, 
          owner: enterprise.owner, 
          numSeats: enterprise.numSeats, 
          numFilledSeats: enterprise.numFilledSeats, 
          subscriptionStart: enterprise.subscriptionStart, 
          subscriptionEnd: enterprise.subscriptionEnd, 
          admins: enterprise.admins
        });
      } else if (userType === 'Owner') {
        // can't have more than one owner, old owner becomes just an admin and the new user becomes new owner
        const oldOwner = enterprise.owner;
        enterprise.admins.push(oldOwner);
        await enterpriseMutation.mutateAsync({
          euuid: enterprise.euuid, 
          name: enterprise.name, 
          owner: newUser.uuid, 
          numSeats: enterprise.numSeats, 
          numFilledSeats: enterprise.numFilledSeats, 
          subscriptionStart: enterprise.subscriptionStart, 
          subscriptionEnd: enterprise.subscriptionEnd, 
          admins: enterprise.admins
        });
      }
      await enterpriseMemberMutation.mutateAsync({euuid: enterprise.euuid, onboarding: Boolean.BOOLEAN_TRUE});
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <dialog ref={dialog} className="backdrop:bg-black/75 block absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 m-0 w-96">
      <div className='flex flex-col p-10'>
        {errorMessage && 
          <p className='relative bottom-2 left-0 text-red-500 m-auto text-center'>{errorMessage}</p> 
        }
        <h1 className="text-xl font-bold m-auto">Add New User</h1>
        <form method="dialog" onSubmit={(e) => addUser(e)} className="flex flex-col gap-1">
          <label>Name<br/><input name="name" type="text" required className="bg-[#e9e9ed] p-1 w-full"/></label>
          <label>Email<br/><input name="email" type="email" required className="bg-[#e9e9ed] p-1 w-full"/></label>
          <label htmlFor="user-type">User Type</label>
          <select defaultValue="" name="userType" id="user-type" required className="p-1 h-[32px]">
            <option value="" disabled>Select a user type</option>
            <option value="User">User</option>
            <option value="Admin">Admin</option>
            {/* <option value="Owner">Owner</option> */}
          </select>
          <div className='flex flex-row m-auto gap-10 mt-5'>
            <button onClick={() => setOpen(false)} className="px-3 py-2 w-[90px] bg-[#ccc] font-bold text-reffyblack">Cancel</button>
            <button type="submit" className="px-3 py-2 bg-reffygold w-[90px] font-bold text-reffyblack">Add User</button>
          </div>
        </form>
      </div>
    </dialog>
  );
}

