import { CombinedModel, useEnterpriseFromUUIDQuery, useGetAllByEnterpriseIdQuery, useUserQuery } from '@cosmogony/reffy-hooks';
import { AdminPermission } from '@cosmogony/reffy-pb/common/pb/common';
import { useNavigate } from 'react-router';
import { EnterpriseModel } from '@cosmogony/reffy-pb/apollo-svc/pkg/pb/apollo';
import Loading from './Loading';

export default function Auth(props: { protected: boolean; children: any }) {
    const navigate = useNavigate();
    const user = useUserQuery();
    const enterpriseQuery = useEnterpriseFromUUIDQuery();
    const baseUrl = window.location.href.includes('localhost') || window.location.href.includes('127.0.0.1')
        ? 'http://localhost:3000'
        : `https://refbuilder.com`;
    
    if (enterpriseQuery.isError) {
        console.log("Error in enterprise users or enterprise query for Auth component");
        window.location.replace(baseUrl);
    }

    const enterpriseModel = enterpriseQuery.data as EnterpriseModel;

    if (!props.protected) {
        return props.children;
    }

    if (user.isLoading || enterpriseQuery.isLoading) {
        return <Loading />;
    } else if (
        user.isError ||
        (user.isSuccess && user.data === undefined) ||
        (user.data?.permissions?.admin !== AdminPermission.ADMIN_ALL && user.data?.uuid !== enterpriseModel.owner && !enterpriseModel.admins.includes(user.data?.uuid))
    ) {
        console.log("Error in user query for Auth component", user.isError);
        window.location.replace(baseUrl);
    } else {
        return props.children;
    }
}
