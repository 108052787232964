import { ChangeEvent, useState } from "react";
import RemoveUserModal from "./RemoveUserModal";
import { CombinedModel, useEditEnterpriseMutation } from "@cosmogony/reffy-hooks";
import { RequestStatus } from "@cosmogony/reffy-pb/litae-svc/pkg/pb/litae";
import { EnterpriseModel } from "@cosmogony/reffy-pb/apollo-svc/pkg/pb/apollo";

export interface Column {
    id: number;
    name: string;
    type: 'text' | 'copy text' | 'dropdown' | 'icon';
    subtext?: string;
    dropdownOptions?: string[];
    iconURL?: string;
}

export interface UserTableProps {
    cols: Column[];
    users: CombinedModel[];
    enterprise: EnterpriseModel;
    mutable: boolean;
    query: string;
    className?: string;
}

export default function UserTable({cols, users, enterprise, mutable, query, className}: UserTableProps) {
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [userToRemove, setUserToRemove] = useState<CombinedModel | null>(null);
    const enterpriseMutation = useEditEnterpriseMutation();
    const rowsPerPage = 10;
    let numPages = Math.ceil(users.length / rowsPerPage);
    const startRow = currentPage * rowsPerPage;
    let endRow = Math.min(startRow + rowsPerPage, users.length);
    let numRows = users.length;
    let dataToRender = users.slice(startRow, endRow);

    if (query) {
        dataToRender = users.filter((rowObj: Object) => {
            for (const key in rowObj) {
                const val = rowObj[key]
                if (typeof val == 'string' && val.toLowerCase().includes(query.toLowerCase())) {
                    return true;
                }
            }
            return false;
        });
        numPages = Math.ceil(dataToRender.length / rowsPerPage);
        endRow = Math.min(startRow + rowsPerPage, dataToRender.length);
        numRows = dataToRender.length;
        if (currentPage !== 0) {
            setCurrentPage(0);
        }
    }

    const copyUrl = (username: string) => {
        const baseUrl = window.location.href.includes('localhost') || window.location.href.includes('127.0.0.1')
            ? 'http://localhost:3000/intake/'
            : `https://refbuilder.com/intake/`;

        navigator.clipboard.writeText(baseUrl + username);
    };

    const changeUserType = (e: ChangeEvent<HTMLSelectElement>, user: CombinedModel) => {
        const newUserType = e.target.value;
        // user -> admin : add to admin list
        // user -> owner : replace owner, add old owner to admin list
        // admin -> owner : remove from admin list, add old owner to admin list
        // admin -> user : remove from admin list
        // owner -> user : owner is now empty
        // owner -> admin : owner is now empty, add old owner to admin list
        if (user.userType === 'User' && newUserType === 'Admin') {
            enterprise.admins.push(user.uuid);
            user.userType = 'Admin';
        } else if (user.userType === 'Admin' && newUserType === 'User') {
            enterprise.admins = enterprise.admins.filter(uuid => uuid !== user.uuid);
            user.userType = 'User';
        }

        enterpriseMutation.mutate({
            euuid: enterprise.euuid, 
            name: enterprise.name, 
            owner: enterprise.owner, 
            numSeats: enterprise.numSeats, 
            numFilledSeats: enterprise.numFilledSeats, 
            subscriptionStart: enterprise.subscriptionStart, 
            subscriptionEnd: enterprise.subscriptionEnd, 
            admins: enterprise.admins
        });
    };

    return (
        <div className="overflow-x-auto">
            <table className={`${className} m-auto p-2 sm:p-3 min-w-full`}>
                <thead className="bg-reffygold p-3">
                    <tr className="p-3">
                        {cols.map(col => {
                            if (col.subtext != null) {
                                return (
                                    <th key={col.id} className="p-3  text-left min-w-[200px] whitespace-nowrap">{col.name}</th>
                                );
                            }
                            return <th key={col.id} rowSpan={2} className="p-3 text-left whitespace-nowrap">{col.name}</th>
                        })}
                        {mutable && <th rowSpan={2} className="p-3 text-left">Remove</th>}
                    </tr>
                    
                    {cols.map(col => {
                        if (col.subtext != null) {
                            return <tr key={col.id}>
                                <th><p className="font-normal text-xs pl-3  text-left -mt-4 sm:-mt-5 whitespace-nowrap">{col.subtext}</p></th>
                            </tr>
                        }
                    })}
                </thead>
                <tbody>
                    {dataToRender.map(row => {
                        const totalRequests = row.responseStats['total'];
                        const completedRequests = row.responseStats[RequestStatus.REQUEST_COMPLETED] || 0;
                        const declinedRequests = row.responseStats[RequestStatus.REQUEST_DECLINED] || 0;
                        return (
                            <tr key={row.username}>
                                <td className="p-2 sm:p-3 bg-white text-left whitespace-nowrap">{row.name}</td>
                                <td className="p-2 sm:p-3 bg-white text-left">{row.email}</td>
                                <td className="p-2 sm:p-3 bg-white text-left">
                                    {row.userType === 'Owner' ? <p className="p-1">Owner</p> : 
                                        <select defaultValue={row.userType} id="user-type" onChange={(e) => changeUserType(e, row)} required className="p-1">
                                            <option value="User">User</option>
                                            <option value="Admin">Admin</option>
                                            {/* <option value="owner">Owner</option> */}
                                        </select>
                                    }
                                </td>
                                <td className="p-2 sm:p-3 bg-white text-left truncate max-w-[200px]">{row.role}</td>
                                <td className="p-2 sm:p-3 bg-white text-left truncate"><div className="flex flex-row"><p className="float-left truncate">{row.username}</p><img src="/assets/images/icons/copybutton.svg" className="cursor-pointer inline-block ml-auto float-right" onClick={() => copyUrl(row.username)}/></div></td>
                                <td className="p-2 sm:p-3 bg-white text-center ">{totalRequests} - {completedRequests} - {declinedRequests}</td>
                                <td className="p-2 sm:p-3 bg-white text-left">{row.lastLoginAt > 0 && new Date(row.lastLoginAt * 1000).toLocaleDateString()}</td>
                                {mutable && <td className="p-2 sm:p-3 bg-white text-left">{row.userType !== 'Owner' && <img src="/assets/images/icons/trashcan.svg" className="cursor-pointer" onClick={() => setUserToRemove(row)} />}</td> }
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <div className="grid grid-cols-3 items-center my-5">
                <p className="text-reffygray">Rows per page: {rowsPerPage}</p>
                <div className="flex flex-row mx-auto w-fit gap-2 sm:p-3">
                    <img src="/assets/images/icons/leftarrow.svg" className={currentPage === 0 ? 'invisible' : 'cursor-pointer'} onClick={() => setCurrentPage(currentPage - 1)} />
                    {[...Array(numPages)].map((e,i) => { 
                        let bgColor = currentPage === i ? "bg-reffygold" : "bg-[#ddd]";
                        return <div key={i} onClick={() => setCurrentPage(i)} className={`${bgColor} w-6 text-center cursor-pointer`}>{i+1}</div>
                    })}
                    <img src="/assets/images/icons/rightarrow.svg" className={currentPage === numPages - 1 || numPages === 0 ? 'invisible' : 'cursor-pointer'} onClick={() => setCurrentPage(currentPage + 1)} />
                </div>
                <p className="ml-auto text-reffygray">{numRows > 0 ? startRow + 1 : 0} {startRow+1 < endRow ? (`- ${endRow}`) : ''} of {numRows}</p>
            </div>
            <RemoveUserModal userToRemove={userToRemove} setUserToRemove={setUserToRemove} enterprise={enterprise}/>
        </div>
  );
}
