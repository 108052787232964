import { EnterpriseModel } from '@cosmogony/reffy-pb/apollo-svc/pkg/pb/apollo';
import { useEnterpriseFromUUIDQuery, useGetAllByEnterpriseIdQuery, useKratosLogoutMutation, useUserQuery }  from "@cosmogony/reffy-hooks";
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from "react-router";
import PrivacyFooter from '../components/PrivacyFooter';
import Navbar from '../components/Navbar';
import Loading from '../components/Loading';


export default function DashboardMain() {
    const baseUrl = window.location.href.includes('localhost') || window.location.href.includes('127.0.0.1')
        ? 'http://localhost:3000'
        : `https://refbuilder.com`;
        
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const logout = useKratosLogoutMutation();
    const userQuery = useUserQuery();
    const enterpriseQuery = useEnterpriseFromUUIDQuery();
    const enterpriseUsersQuery = useGetAllByEnterpriseIdQuery();
    const enterpriseModel = enterpriseQuery.data as EnterpriseModel;

    if (userQuery.isLoading || enterpriseQuery.isLoading || enterpriseUsersQuery.isLoading || logout.isLoading) {
        return <Loading />
    }

    if (userQuery.isError || enterpriseQuery.isError || enterpriseUsersQuery.isError || logout.isError) {
        console.log("potential user query error inside dashboard component:", userQuery.error)
        console.log("potential enterprise query error inside dashboard component:", enterpriseQuery.error)
        console.log("potential enterprise users query error inside dashboard component:", enterpriseUsersQuery.error)
        console.log("potential logout mutation error inside dashboard component:", logout.error)
        window.location.replace(baseUrl);
    }

    return (
        <div className='bg-reffybg font-pressura min-h-screen relative flex flex-col w-full'>
            <div className='min-[1440px]:w-[1440px] mx-auto'>
                <Navbar />
                <div className="mx-auto pb-[40px] w-full">
                    <h1 className="text-[38px] text-center text-reffyblack font-semibold">{enterpriseModel.name}</h1>
                    <h2 className="text-2xl text-center text-reffygray">Welcome Back, Administrator!</h2>
                    <div className="flex flex-wrap flex-col items-center md:flex-row m-auto w-full">
                        <DashboardCard title='Users' subtitle='Manage user info' icon='assets/images/icons/People2.png' onClick={() => navigate('/users')}/>
                        <DashboardCard title='Account Info' subtitle='Setting, subscription, FAQ' icon='assets/images/icons/Gear.png' onClick={() => navigate('/account')}/>
                        <DashboardCard title='Metrics' subtitle='View user metrics' icon='assets/images/icons/LineChart.png' onClick={() => navigate('/')} disabled/>
                    </div>
                </div>
            </div>
            <PrivacyFooter />
        </div>
    );
}

interface DashboardCardProps {
    title: string;
    subtitle: string;
    icon: string;
    onClick: () => void;
    disabled?: boolean;
}

function DashboardCard({title, subtitle, icon, onClick, disabled}: DashboardCardProps) {
    return <div className="px-4 mb-8 mt-32 m-auto">
        <div className="bg-white border w-[300px] min-[430px]:w-[400px] h-[334px] shadow-md p-12 relative align-middle">
            <div className="absolute top-[-100px] left-1/2 transform -translate-x-1/2 w-48 h-48 bg-reffygold2 rounded-full flex items-center justify-center content-center m-auto mb-10">
                <img className="w-24 h-24" src={icon} alt="black icon" />
            </div>
            <p className="text-3xl text-reffyblack font-medium text-center align-middle mt-20">{title}</p>
            <p className="text-reffygray text-center pb-5 align-middle text-2xl">{subtitle}</p>
            <div className="flex justify-center">
                {disabled ? (
                    <button className="bg-reffydisabled font-bold text-reffygray px-5 py-3 text-lg" disabled onClick={onClick}>COMING SOON...</button >
                ) : (
                    <button className="bg-reffygold font-bold text-reffyblack px-5 py-3 text-lg hover:shadow-lg" onClick={onClick}>GET STARTED</button>
                )}
            </div>
        </div>
    </div>;
}
