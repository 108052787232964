/* eslint-disable */
import _m0 from "protobufjs/minimal";

export const protobufPackage = "common";

export enum UserPermission {
  USER_BANNED = 0,
  USER_NONE = 1,
  USER_FREE = 2,
  USER_PRO = 3,
  UNRECOGNIZED = -1,
}

export function userPermissionFromJSON(object: any): UserPermission {
  switch (object) {
    case 0:
    case "USER_BANNED":
      return UserPermission.USER_BANNED;
    case 1:
    case "USER_NONE":
      return UserPermission.USER_NONE;
    case 2:
    case "USER_FREE":
      return UserPermission.USER_FREE;
    case 3:
    case "USER_PRO":
      return UserPermission.USER_PRO;
    case -1:
    case "UNRECOGNIZED":
    default:
      return UserPermission.UNRECOGNIZED;
  }
}

export function userPermissionToJSON(object: UserPermission): number {
  switch (object) {
    case UserPermission.USER_BANNED:
      return 0;
    case UserPermission.USER_NONE:
      return 1;
    case UserPermission.USER_FREE:
      return 2;
    case UserPermission.USER_PRO:
      return 3;
    case UserPermission.UNRECOGNIZED:
    default:
      return -1;
  }
}

export enum OrgPermission {
  ORG_BANNED = 0,
  ORG_NONE = 1,
  ORG_FREE = 2,
  ORG_PRO = 3,
  UNRECOGNIZED = -1,
}

export function orgPermissionFromJSON(object: any): OrgPermission {
  switch (object) {
    case 0:
    case "ORG_BANNED":
      return OrgPermission.ORG_BANNED;
    case 1:
    case "ORG_NONE":
      return OrgPermission.ORG_NONE;
    case 2:
    case "ORG_FREE":
      return OrgPermission.ORG_FREE;
    case 3:
    case "ORG_PRO":
      return OrgPermission.ORG_PRO;
    case -1:
    case "UNRECOGNIZED":
    default:
      return OrgPermission.UNRECOGNIZED;
  }
}

export function orgPermissionToJSON(object: OrgPermission): number {
  switch (object) {
    case OrgPermission.ORG_BANNED:
      return 0;
    case OrgPermission.ORG_NONE:
      return 1;
    case OrgPermission.ORG_FREE:
      return 2;
    case OrgPermission.ORG_PRO:
      return 3;
    case OrgPermission.UNRECOGNIZED:
    default:
      return -1;
  }
}

export enum AdminPermission {
  ADMIN_BANNED = 0,
  ADMIN_NONE = 1,
  ADMIN_ALL = 2,
  UNRECOGNIZED = -1,
}

export function adminPermissionFromJSON(object: any): AdminPermission {
  switch (object) {
    case 0:
    case "ADMIN_BANNED":
      return AdminPermission.ADMIN_BANNED;
    case 1:
    case "ADMIN_NONE":
      return AdminPermission.ADMIN_NONE;
    case 2:
    case "ADMIN_ALL":
      return AdminPermission.ADMIN_ALL;
    case -1:
    case "UNRECOGNIZED":
    default:
      return AdminPermission.UNRECOGNIZED;
  }
}

export function adminPermissionToJSON(object: AdminPermission): number {
  switch (object) {
    case AdminPermission.ADMIN_BANNED:
      return 0;
    case AdminPermission.ADMIN_NONE:
      return 1;
    case AdminPermission.ADMIN_ALL:
      return 2;
    case AdminPermission.UNRECOGNIZED:
    default:
      return -1;
  }
}

export enum Boolean {
  BOOLEAN_UNDEFINED = 0,
  BOOLEAN_FALSE = 1,
  BOOLEAN_TRUE = 2,
  UNRECOGNIZED = -1,
}

export function booleanFromJSON(object: any): Boolean {
  switch (object) {
    case 0:
    case "BOOLEAN_UNDEFINED":
      return Boolean.BOOLEAN_UNDEFINED;
    case 1:
    case "BOOLEAN_FALSE":
      return Boolean.BOOLEAN_FALSE;
    case 2:
    case "BOOLEAN_TRUE":
      return Boolean.BOOLEAN_TRUE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Boolean.UNRECOGNIZED;
  }
}

export function booleanToJSON(object: Boolean): number {
  switch (object) {
    case Boolean.BOOLEAN_UNDEFINED:
      return 0;
    case Boolean.BOOLEAN_FALSE:
      return 1;
    case Boolean.BOOLEAN_TRUE:
      return 2;
    case Boolean.UNRECOGNIZED:
    default:
      return -1;
  }
}

export interface Permissions {
  user: UserPermission;
  org: OrgPermission;
  admin: AdminPermission;
}

export interface DocumentRecord {
  url: string;
  name: string;
}

function createBasePermissions(): Permissions {
  return { user: 0, org: 0, admin: 0 };
}

export const Permissions = {
  encode(message: Permissions, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.user !== 0) {
      writer.uint32(8).int32(message.user);
    }
    if (message.org !== 0) {
      writer.uint32(16).int32(message.org);
    }
    if (message.admin !== 0) {
      writer.uint32(24).int32(message.admin);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Permissions {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePermissions();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.user = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.org = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.admin = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Permissions {
    return {
      user: isSet(object.user) ? userPermissionFromJSON(object.user) : 0,
      org: isSet(object.org) ? orgPermissionFromJSON(object.org) : 0,
      admin: isSet(object.admin) ? adminPermissionFromJSON(object.admin) : 0,
    };
  },

  toJSON(message: Permissions): unknown {
    const obj: any = {};
    message.user !== undefined && (obj.user = userPermissionToJSON(message.user));
    message.org !== undefined && (obj.org = orgPermissionToJSON(message.org));
    message.admin !== undefined && (obj.admin = adminPermissionToJSON(message.admin));
    return obj;
  },

  create<I extends Exact<DeepPartial<Permissions>, I>>(base?: I): Permissions {
    return Permissions.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Permissions>, I>>(object: I): Permissions {
    const message = createBasePermissions();
    message.user = object.user ?? 0;
    message.org = object.org ?? 0;
    message.admin = object.admin ?? 0;
    return message;
  },
};

function createBaseDocumentRecord(): DocumentRecord {
  return { url: "", name: "" };
}

export const DocumentRecord = {
  encode(message: DocumentRecord, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.url !== "") {
      writer.uint32(10).string(message.url);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DocumentRecord {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDocumentRecord();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.url = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DocumentRecord {
    return { url: isSet(object.url) ? String(object.url) : "", name: isSet(object.name) ? String(object.name) : "" };
  },

  toJSON(message: DocumentRecord): unknown {
    const obj: any = {};
    message.url !== undefined && (obj.url = message.url);
    message.name !== undefined && (obj.name = message.name);
    return obj;
  },

  create<I extends Exact<DeepPartial<DocumentRecord>, I>>(base?: I): DocumentRecord {
    return DocumentRecord.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DocumentRecord>, I>>(object: I): DocumentRecord {
    const message = createBaseDocumentRecord();
    message.url = object.url ?? "";
    message.name = object.name ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
