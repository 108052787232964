import Empty from '../components/Empty';
import UserManagement from '../pages/UserManagement';
import DashboardHome from '../pages/Dashboard';
import SettingsPage from '../pages/settings/Settings';

export interface IRoute {
    path: string;
    component: any;
    name: string;
    protected: boolean; // should the route be available to public users or private
}

const routes: IRoute[] = [
    {
        path: '/',
        component: DashboardHome,
        name: 'Dashboard Home Page',
        protected: false
    },
    {
        path: '/users',
        component: UserManagement,
        name: 'User Management',
        protected: false,
    },
    {
        path: '/account',
        component: SettingsPage,
        name: 'Settings Page',
        protected: false,
    },
];

export default routes;
