import {useRef, useLayoutEffect, useEffect} from 'react';

interface SalesContact {
  name: string;
  email: string;
  phone: string;
}

interface ContactModalProps {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  numSeats: number;
  salesContact: SalesContact;
}

export default function ContactModal({open, setOpen, numSeats, salesContact}: ContactModalProps) {
  const dialog = useRef<HTMLDialogElement>(null);

  useEffect(() => {
    document.addEventListener('keydown', (e) => {
      if (e.code === 'Escape') {
        setOpen(false);
        dialog.current?.close();
      }
    })
  }, [])
  
  useLayoutEffect(() => {
    dialog.current?.showModal();
  }, [dialog]);

  return (
    <dialog ref={dialog} className="backdrop:bg-black/75 block absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col m-0 p-10 w-96">
      <h1 className="text-xl font-bold m-auto">You're out of seats!</h1>
      <p>Your subscription is currently using {numSeats}/{numSeats} seats. Please contact your Reffy Inc. sales representative to add more seats to your enterprise subscription.</p>
      <div className='m-auto text-center my-2'>
        <p>Name: {salesContact.name}</p>
        <p>Email: <a href={`mailto:${salesContact.email}`} className="text-blue-800 underline">{salesContact.email}</a></p>
        <p>Phone Number: <a href={`tel:${salesContact.phone}`} className="text-blue-800 underline">{salesContact.phone}</a></p>
      </div>
      <button onClick={() => setOpen(false)} className="px-8 py-2 bg-reffygold font-bold w-fit m-auto">Okay</button>
    </dialog>
  );
}

