export const API_URL =
    typeof window !== 'undefined'
        ? window.location.href.includes('localhost') ||
          window.location.href.includes('127.0.0.1')
            ? 'http://localhost:8000/api/v2'
            : `https://api.refbuilder.com/api/v2`
        : 'http://localhost:8000/api/v2';

export async function genericHandler(
    method: 'GET' | 'POST',
    credentials: RequestCredentials | undefined,
    endpoint: string,
    obj?: Object,
    bearer?: string
) {
    const options: RequestInit = {
        method: method,
        credentials: credentials,
        headers: {},
    };

    if (method === 'POST') {
        options.headers!['Content-Type'] = 'application/json';
        options.body = JSON.stringify(obj);
    }

    if (bearer) {
        options.headers!['Authorization'] = `Bearer ${bearer}`;
    }

    const data = await fetch(`${API_URL + endpoint}`, options)
        .then(async (response) => {
            return await response.json();
        })
        .catch((err) => {
            console.log(err);
            return { error: err };
        });
    return data;
}
